import styled from "styled-components";

const InputGridGroup = styled.div`
  display: grid;
  align-items: center;
  column-gap: 6px;
  grid-auto-flow: column dense;
`;
export const InputGroupLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  margin: 4px 0;
  & > div:first-of-type {
    flex: 1;
  }
`;
export const InputGroup = styled.div`
  margin: 8px 0;
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ flex }) =>
    flex &&
    `
  display: flex;
  align-items: center;
  grid-gap: 8px;
  flex-wrap: nowrap;
  `}
`;

export default InputGridGroup;
