import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Tick from "../svgs/Tick";

const Square = styled.div`
  border: ${({ border }) => border || "1px solid #fff"};
  border-radius: 4px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  padding: 2px;
  display: flex;
`;

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: inherit;
  padding: 8px 0;
  color: #666;
  ${({ disabled }) =>
    !disabled &&
    `
  cursor: pointer;
  color: #fff;
  `}
  &:hover {
    ${({ disabled }) =>
      !disabled &&
      `
    & ${Square} {
      border-color: #999;
    }
    `}
  }
  ${({ disabled }) =>
    disabled &&
    `
    & ${Square} {
      border-color: #444;

    }
    &:hover {
    }
    
  `}
`;
const Label = styled.div`
  margin-left: 8px;
`;

const Checkbox = ({
  value: defaultValue,
  onUpdate,
  label,
  size = 20,
  border,
  icon: Icon = Tick,
  disabled,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = useCallback(
    (v) => {
      setValue((prev) => {
        onUpdate(!prev);
        return !prev;
      });
    },
    [onUpdate]
  );
  return (
    <CheckboxWrapper
      onClick={disabled || !onUpdate ? null : onChange}
      disabled={disabled}
    >
      <Square size={size} border={border}>
        <Icon
          color={!value ? "rgba(255,255,255, 0.1)" : disabled ? "#999" : "#fff"}
        />
      </Square>
      {label && <Label>{label}</Label>}
    </CheckboxWrapper>
  );
};
export default Checkbox;
