import React, { useCallback, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { useScene } from "./sceneContext";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

const RecorderContext = React.createContext();

const isEven = (n) => !(n % 2);
const getScale = (width, aspect) => {
  let outputWidth = width;
  let outputHeight = Math.floor(width / aspect);
  if (!isEven(width)) {
    outputWidth -= 1;
    outputHeight = Math.floor(outputWidth / aspect);
  }

  if (aspect < 1) {
    outputHeight = width;
    outputWidth = Math.floor(outputHeight * aspect);
    if (!isEven(outputWidth)) {
      outputWidth = outputWidth - 1;
    }
  }

  if (!isEven(outputHeight)) {
    outputHeight -= 1;
  }
  return `${outputWidth}:${outputHeight}`;
};

export const RecorderProvider = ({ children, canvasRef }) => {
  const { sceneConfig } = useScene();
  const [saving, setSaving] = useState(false);
  const [start, setStart] = useState(false);
  const [, setTimerIntervalId] = useState(null);
  const [timeLapsed, setTimeLapsed] = useState(0.1);
  const recorder = useRef();

  const [recorderJobs, setRecorderJobs] = useState([]);

  const takeScreenshot = useCallback(() => {
    setRecorderJobs((prev) => [...prev, { id: v4(), type: "screenshot" }]);
  }, []);
  const removeJob = useCallback((id) => {
    setRecorderJobs((prev) => prev.filter((p) => p.id !== id));
  }, []);
  const startRecording = useCallback(async () => {
    let chunks = [];
    // setRecorderJobs((prev) => [
    //   ...prev,
    //   { id: v4(), type: "record", config: sceneConfig },
    // ]);

    const {
      fps = 24,
      outputWidth,
      aspect,
      outputName = "output",
    } = sceneConfig.camera;
    const outputFileName = `${outputName}.mp4`;

    var cStream = canvasRef.current.captureStream(fps);
    let newRecorder = new MediaRecorder(cStream, {
      // mimeType: "video/webm;codecs=vp8,opus",
    });
    recorder.current = newRecorder;
    newRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    newRecorder.onstop = async (e) => {
      const blob = new Blob(chunks);
      setSaving(true);

      // const vidURL = URL.createObjectURL(blob);
      // const vid = document.createElement("video");
      // vid.controls = true;
      // vid.src = vidURL;
      // vid.style.width = 640;
      // vid.style.height = 360;
      // vid.onended = function () {
      //   URL.revokeObjectURL(vidURL);
      // };
      // const link = document.createElement("a");
      // link.setAttribute("href", vidURL);
      // link.setAttribute("target", "_blank");
      // link.download = `cine-${new Date()}.webm`;
      // link.click();
      // chunks = [];

      const ffmpeg = createFFmpeg();
      await ffmpeg.load();

      ffmpeg.setProgress((state) => {
        console.log("save progress", state);
      });

      var reader = new FileReader();
      reader.onload = async (evt) => {
        const tmpFile = "tmp.webm";

        ffmpeg.FS("writeFile", tmpFile, await fetchFile(evt.target.result));

        const scale = getScale(outputWidth, aspect);

        await ffmpeg.run(
          "-i",
          tmpFile,
          // "-vcodec",
          // "copy",
          // "-qscale",
          // "0",
          "-r",
          String(fps),
          "-s",
          scale,
          outputFileName
        );

        const data = ffmpeg.FS("readFile", outputFileName);
        ffmpeg.FS("unlink", tmpFile);

        setSaving(false);
        setStart(false);
        setTimeLapsed(0);

        var mp4blob = new Blob([data.buffer], { type: "video/mp4" });

        var dataUrl = window.URL.createObjectURL(mp4blob);
        var link = document.createElement("a");
        link.href = dataUrl;
        link.download = outputFileName;
        link.click();
      };

      reader.readAsArrayBuffer(blob);
    };

    setStart(true);
    newRecorder.start();
    setTimeLapsed(0);
    const intervalId = setInterval(() => {
      setTimeLapsed((prev) => prev + 0.01);
    }, 10);
    setTimerIntervalId(intervalId);
  }, [sceneConfig, canvasRef]);

  const stopRecording = useCallback(() => {
    // setRecorderJobs((prev) => [
    //   ...prev,
    //   { id: v4(), type: "end-record", config: sceneConfig },
    // ]);

    setTimerIntervalId((prev) => {
      clearInterval(prev);
      return null;
    });

    recorder.current.stop();
  }, []);

  const value = useMemo(
    () => ({
      startRecording,
      stopRecording,
      start,
      timeLapsed,
      takeScreenshot,
      recorderJobs,
      removeJob,
      saving,
    }),
    [
      startRecording,
      start,
      stopRecording,
      timeLapsed,
      takeScreenshot,
      recorderJobs,
      removeJob,
      saving,
    ]
  );

  return (
    <RecorderContext.Provider value={value}>
      {children}
    </RecorderContext.Provider>
  );
};

export const useRecorder = () => React.useContext(RecorderContext);
