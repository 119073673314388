import * as THREE from "three";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 } from "uuid";
import { acceptedFileType, angles, scenes, shots } from "./data";
import gsap from "gsap";
import set from "lodash/set";
import niceColor from "nice-color-palettes";
import omit from "lodash/omit";

const SceneContext = React.createContext();
const getRandomColor = () => {
  const randomColorSet = Math.floor(Math.random() * 100);
  return niceColor[randomColorSet][
    Math.floor(Math.random() * niceColor[randomColorSet].length)
  ];
};

export const getMeshFocusCenter = (mesh) => {
  var bbox = new THREE.Box3().setFromObject(mesh);
  return {
    position: {
      x: mesh.position.x,
      y: mesh.position.y,
      z: mesh.position.z,
    },
    bbox: {
      x: bbox.max.x - bbox.min.x,
      y: bbox.max.y - bbox.min.y,
      z: bbox.max.z - bbox.min.z,
    },
  };
};

const makeShot = ({ index, ...props }) => {
  return {
    id: v4(),
    name: `Shot ${index + 1}`,
    ...props,
  };
};
export const makeModel = ({ name, localPath, ...props }) => ({
  id: v4(),
  name: name || v4(),
  color: getRandomColor(),
  isSelected: false,
  isAnimating: false,
  visible: true,
  ...props,
});
export const getAnimationStatus = (actions = {}) =>
  Object.keys(actions).reduce((a, c) => {
    const action = actions[c];
    const isPlaying = !action.paused && action.time > 0;
    return {
      ...a,
      [c]: isPlaying,
    };
  }, {});

const getFile = ({ localPath, ...props }) =>
  new Promise(async (resolve, reject) => {
    if (!acceptedFileType.includes(props.fileType)) {
      resolve(null);
      return;
    }
    let output = props;
    if (localPath) {
      // Todo: figure out how or if its even possible
      const result = await fetch(localPath);
      const blob = await result.blob();
      const url = URL.createObjectURL(blob);
      output = { ...props, src: url };
    }

    resolve(makeModel(output));
  });

export const SceneProvider = ({ children, scene: activeSceneProp }) => {
  const [models, setModels] = useState([]);
  const [lights, setLights] = useState([]);
  const [sceneConfig, setSceneConfig] = useState(activeSceneProp?.config);

  const [selected, setSelected] = React.useState([]);
  const [meshOnSearch, setMeshOnSearch] = React.useState();
  const [activeScene, setActiveScene] = useState();

  const [scene, setScene] = useState();
  const [camera, setCamera] = useState();
  const [canvasConfig, setCanvasConfig] = useState({
    height: 0,
    width: 0,
    top: 0,
    left: 0,
  });
  const [savedShots, setSavedShots] = useState([]);

  const getSceneSnapshot = useCallback(() => {
    // get snapshots of model/light/camera position/rotation/
    const getCoord = (vec3) => ({ x: vec3.x, y: vec3.y, z: vec3.z });
    const modelSnapshot = models.map((m) => {
      const mesh = scene.getObjectByName(m.id);
      return {
        ...m,
        position: getCoord(mesh.position),
        scale: getCoord(mesh.scale),
        rotation: getCoord(mesh.rotation),
      };
    });
    const cameraSnapshot = {
      position: getCoord(camera.position),
      scale: getCoord(camera.scale),
      rotation: getCoord(camera.rotation),
      fov: camera.fov,
      zoom: camera.zoom,
    };
    const lightSnapshot = lights;

    return {
      models: modelSnapshot,
      camera: cameraSnapshot,
      lights: lightSnapshot,
    };
  }, [camera, models, scene, lights]);

  const addShots = useCallback(
    (callback) => {
      const snapshot = getSceneSnapshot();

      setSavedShots((prev) => {
        const newShot = makeShot({
          index: prev.length,
          ...snapshot,
        });
        callback?.(newShot);
        return [...prev, newShot];
      });
    },
    [getSceneSnapshot]
  );

  const updateShot = useCallback(
    (shotId) => {
      const snapshot = getSceneSnapshot();
      setSavedShots((prev) => {
        let newShots = [...prev];
        const targetIndex = prev.findIndex((m) => m.id === shotId);
        newShots[targetIndex] = {
          ...prev[targetIndex],
          ...snapshot,
        };
        return newShots;
      });
    },
    [getSceneSnapshot]
  );

  const [cameraConfig, setCameraConfig] = useState({
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    fov: 35,
    zoom: 1,
    protagonist: null,
  });

  const effectComposerRef = useRef();
  const [activeGroup, setActiveGroup] = useState();

  const fetchModels = useCallback(async (list) => {
    const loadedModels = await Promise.all(list.map((l) => getFile(l)));
    const convertVec3 = (v3) => [v3.x, v3.y, v3.z];

    setModels(
      loadedModels
        .filter((p) => !!p)
        .map((p) => ({
          ...p,
          position: convertVec3(p.position),
          scale: convertVec3(p.scale),
          rotation: convertVec3(p.rotation),
        }))
    );
  }, []);

  const [effectConfig, setEffectConfig] = useState({
    enableDepth: true,
    transformMode: "translate",
    depthTarget: { x: 0, y: 0, z: 0 },
    focusDistance: 0,
    depthFocalLength: 0.01,
    bokehScale: 10,
    shot: "mediumShot",
    hideOverlay: false,
    autoFocus: false,
  });

  const getProtagonistCenter = useCallback(() => {
    if (!cameraConfig.protagonist) {
      return {
        position: { x: 0, y: 0, z: 0 },
        bbox: { x: 1, y: 1, z: 1 },
      };
    }
    const protagonistMesh = scene.getObjectByName(
      cameraConfig.protagonist,
      true
    );
    const protagonistCenter = getMeshFocusCenter(protagonistMesh);
    return protagonistCenter;
  }, [cameraConfig.protagonist, scene]);

  const updateDepthTarget = useCallback(
    ({ x, y, z }) => {
      let i = effectConfig.depthTarget;
      gsap.to(i, {
        x: x !== undefined ? x : i.x,
        y: y !== undefined ? y : i.y,
        z: z !== undefined ? z : i.z,
        onUpdate: (v) => {
          setEffectConfig((prev) => ({ ...prev, depthTarget: v }));
        },
        onUpdateParams: [i],
      });
    },
    [effectConfig]
  );

  const updateActiveGroup = useCallback(
    (newActiveGroup) => {
      setActiveGroup(() => {
        if (!newActiveGroup) return null;
        const { mesh, model } = newActiveGroup;
        // const animationStatus = getAnimationStatus(
        //   newActiveGroup.model.actions
        // );
        // const visible = newActiveGroup.mesh.visible;
        return {
          mesh,
          model,
          modelId: model.id,
          // model: { ...model, isAnimating: animationStatus, visible },
        };
      });
      if (newActiveGroup?.mesh && effectConfig.autoFocus) {
        updateDepthTarget({
          x: newActiveGroup.mesh.position.x,
          y: newActiveGroup.mesh.position.y,
          z: newActiveGroup.mesh.position.z,
        });
      }
    },
    [effectConfig.autoFocus, updateDepthTarget]
  );

  const updateActiveActions = useCallback((actions = {}) => {
    const animationStatus = getAnimationStatus(actions);

    setActiveGroup((prev) => ({
      ...prev,
      model: { ...prev.model, actions, isAnimating: animationStatus },
    }));
  }, []);

  const updateActiveMeshProperties = useCallback((mesh) => {
    setActiveGroup((prev) => ({
      ...prev,
      mesh,
    }));
  }, []);
  const updateActiveModel = useCallback((model) => {
    setModels((prev) => prev.map((m) => (m.id === model.id ? model : m)));
    setActiveGroup((prev) => ({
      ...prev,
      model,
    }));
  }, []);

  const addModel = useCallback((model) => {
    setModels((prev) => [...prev, model]);
  }, []);

  const updateModel = useCallback((model) => {
    setModels((prev) => {
      let newModels = [...prev];
      const targetIndex = prev.findIndex((m) => m.id === model.id);
      newModels[targetIndex] = { ...prev[targetIndex], ...model };
      return newModels;
    });
  }, []);
  // Todo: fix hack!
  const cancelSelection = useCallback((action) => {
    let prevSelected, prevActiveGroup;
    setSelected((prev) => {
      prevSelected = prev;
      return [];
    });
    setActiveGroup((prev) => {
      prevActiveGroup = prev;
      return null;
    });
    setTimeout(() => {
      action();
      setSelected(prevSelected);
      setActiveGroup(prevActiveGroup);
    }, 0);
  }, []);

  const clearSelected = useCallback(() => {
    setSelected([]);
    setActiveGroup(null);
  }, []);

  const removeModel = useCallback(
    (modelToBeRemoved) => {
      clearSelected();
      setModels((prev) => {
        return prev.filter((p) => p.id !== modelToBeRemoved?.id);
      });
    },
    [clearSelected]
  );

  const resetAnimation = useCallback(
    (model, action, key) => {
      action.stop();
      updateModel({
        ...model,
        isAnimating: { ...model.isAnimating, [key]: false },
      });
    },
    [updateModel]
  );
  const handleAnimation = useCallback(
    (model, action, config = { timeScale: 1, fromStart: false }) => {
      const enableBlend = model.enableBlend;
      const shouldPauseAction =
        model.isAnimating?.[action] && !config.fromStart;

      const isAnimating = Object.keys(model.isAnimating).reduce(
        (a, c) => {
          if (c === action) return a;
          if (!enableBlend) {
            model.actions[c].reset();
            model.actions[c].timeScale = 1;
            model.actions[c].fadeOut(0);
            a[c] = false;
          }
          return a;
        },
        { ...model.isAnimating, [action]: !shouldPauseAction }
      );
      const updatedModel = {
        ...model,
        isAnimating,
      };

      if (shouldPauseAction) {
        updatedModel.actions[action].paused = true;
      } else {
        updatedModel.actions[action].timeScale = config.timeScale;
        updatedModel.actions[action].paused = false;
        updatedModel.actions[action].reset().play();
      }
      updateModel(updatedModel);
    },
    [updateModel]
  );

  const selectMesh = useCallback(
    (group) => {
      updateModel(group.model);
      updateActiveGroup(group);
      setSelected([group.mesh]);
      setMeshOnSearch(undefined);
    },
    [updateActiveGroup, updateModel]
  );

  const dollyZoom = useCallback(
    (dollyFov) => {
      // const zOffset = 1;
      const zOffset = 0;

      const protagonistCenter = getProtagonistCenter();

      const fov = camera.fov;
      // const width = protagonistCenter.bbox.y * 0.5;
      const width = 1;
      const distance =
        width / (2 * Math.tan(THREE.MathUtils.degToRad(fov * 0.5))) +
        protagonistCenter.position.z;
      //  +
      // protagonistCenter.bbox.z;
      // let distance =
      // width / (2 * Math.tan(THREE.MathUtils.degToRad(fov * 0.5))) +
      // effectConfig.depthTarget.z -
      // zOffset;

      // camera.fov = dollyFov;
      // camera.updateProjectionMatrix();
      gsap.to(
        camera,
        {
          fov: dollyFov,
          onUpdate: () => {
            camera.updateProjectionMatrix();
          },
        },
        "start"
      );
      gsap.to(camera.position, {
        z: distance,
      });
    },
    [camera, getProtagonistCenter]
  );

  const zoom = useCallback(
    (zoomLevel) => {
      gsap.to(camera, {
        zoom: zoomLevel,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        },
      });
    },
    [camera]
  );
  const changeAngle = useCallback(
    (angleType, targetId) => {
      const config = angles[angleType];
      if (!config) return;

      let targetX = config.position.x;
      let targetY = config.position.y;
      let targetZ = config.position.z + effectConfig.depthTarget.z;

      const cameraTargetId = targetId || cameraConfig.protagonist;
      if (cameraTargetId && !!config.getConfig) {
        const protagonistMesh = scene.getObjectByName(cameraTargetId, true);

        const protagonistCenter = getMeshFocusCenter(protagonistMesh);
        // const protagonistCenter = getProtagonistCenter();
        const model = models.find((m) => m.id === cameraTargetId);
        const bboxOffset = model?.bboxOffset || { x: 0, y: 0, z: 0 };
        const result = config.getConfig(protagonistCenter);

        targetX = result.position.x + bboxOffset.x;
        targetY = result.position.y + bboxOffset.y;
        targetZ = result.position.z + bboxOffset.z;
      }
      gsap.to(camera.position, {
        x: targetX,
        y: targetY,
        z: targetZ,
        onUpdate: () => {
          setCameraConfig((prev) => {
            return {
              ...prev,
              position: {
                x: camera.position.x,
                y: camera.position.y,
                z: camera.position.z,
              },
            };
          });
        },
      });
      gsap.to(camera.rotation, {
        x: config.rotation.x,
        y: config.rotation.y,
        z: config.rotation.z,
        onUpdate: () => {
          setCameraConfig((prev) => {
            return {
              ...prev,
              rotation: {
                x: camera.rotation.x,
                y: camera.rotation.y,
                z: camera.rotation.z,
              },
            };
          });
        },
      });
      camera.fov = config.fov;
      camera.updateProjectionMatrix();
      setCameraConfig((prev) => ({ ...prev, fov: config.fov }));
    },
    [camera, effectConfig, cameraConfig, scene, models]
  );
  const clearShot = useCallback(() => {
    setModels([]);
    setLights([]);
    setSavedShots([]);
  }, []);

  const loadShot = useCallback(
    (shot) => {
      // const shot = savedShots.find((p) => p.id === shotId);
      // console.log(savedShots);
      clearSelected();

      if (!shot) {
        clearShot();
        return;
      }
      fetchModels(shot.models);

      setLights(shot.lights);

      // Update camera
      const cameraOfShot = shot.camera;

      camera.position.x = cameraOfShot.position.x;
      camera.position.y = cameraOfShot.position.y;
      camera.position.z = cameraOfShot.position.z;

      camera.scale.x = cameraOfShot.scale.x;
      camera.scale.y = cameraOfShot.scale.y;
      camera.scale.z = cameraOfShot.scale.z;

      camera.rotation.x = cameraOfShot.rotation.x;
      camera.rotation.y = cameraOfShot.rotation.y;
      camera.rotation.z = cameraOfShot.rotation.z;

      // camera.position.x = cameraOfShot.position[0];
      // camera.position.y = cameraOfShot.position[1];
      // camera.position.z = cameraOfShot.position[2];

      // camera.scale.x = cameraOfShot.scale[0];
      // camera.scale.y = cameraOfShot.scale[1];
      // camera.scale.z = cameraOfShot.scale[2];

      // camera.rotation.x = cameraOfShot.rotation[0];
      // camera.rotation.y = cameraOfShot.rotation[1];
      // camera.rotation.z = cameraOfShot.rotation[2];

      camera.fov = cameraOfShot.fov;
      camera.zoom = cameraOfShot.zoom;
      camera.updateProjectionMatrix();

      setCameraConfig(cameraOfShot);

      setSceneConfig((prev) => ({
        ...prev,
        // camera: {...prev.camera, position: cameraOfShot.initialPosition}
      }));
      // changeAngle(cameraOfShot.angle);
      // setSceneConfig(scene.config);
    },
    [clearSelected, fetchModels, camera]
  );

  const loadScene = useCallback(
    (scene) => {
      setActiveScene(scene);
      clearSelected();
      setSceneConfig(scene.config);

      setSavedShots(
        scene.shots.map((s, idx) => makeShot({ ...s, index: idx }))
      );
      // if (scene.shots.length > 0) {
      loadShot(scene.shots[0]);
      // }
    },
    [clearSelected, loadShot]
  );
  const focusAtMesh = useCallback(
    (modelId) => {
      changeAngle("default", modelId);
    },
    [changeAngle]
  );

  const changeShot = useCallback(
    (shotType) => {
      const config = shots[shotType];
      if (!config) return;
      setEffectConfig((prev) => ({ ...prev, shot: shotType }));
      gsap.to(camera, {
        fov: config.fov,
        onUpdate: () => {
          camera.updateProjectionMatrix();
          setCameraConfig((prev) => ({
            ...prev,
            fov: config.fov,
          }));
        },
      });
    },
    [camera]
  );

  const onCameraUpdate = useCallback(
    (updateKey, newValue) => {
      setCameraConfig((prev) => {
        const newConfig = prev;
        set(newConfig, updateKey, newValue);
        return newConfig;
      });
      set(camera, updateKey, newValue);
    },
    [camera]
  );

  const selectFile = useCallback(
    (file, callback) => {
      const url = URL.createObjectURL(file);

      const name = file.name.split(".");
      const ext = name.pop();

      if (ext === "json") {
        var reader = new FileReader();
        reader.onload = (e) => {
          var newScene = JSON.parse(e.target.result);
          if (!newScene?.config || !newScene?.shots) return;
          loadScene(newScene);
        };
        reader.readAsText(file);
        callback?.();

        return;
      }

      const newModel = makeModel({
        name: name,
        src: url,
        loadScale: 0.005,
        fileType: ext,
      });
      callback?.();
      addModel(newModel);
    },
    [addModel, loadScene]
  );

  useEffect(() => {
    if (camera) {
      setCameraConfig({
        position: {
          x: camera.position.x,
          y: camera.position.y,
          z: camera.position.z,
        },
        rotation: {
          x: camera.rotation.x,
          y: camera.rotation.y,
          z: camera.rotation.z,
        },
        fov: camera.fov,
        zoom: camera.zoom,
      });
      changeShot(effectConfig.shot);
    }
  }, [camera, changeShot]);

  const updateProtagonist = useCallback((id) => {
    setCameraConfig((prev) => ({ ...prev, protagonist: id }));
  }, []);

  // useEffect(() => {
  //   const keyBinding = (e) => {
  //     switch (e.key) {
  //       case "Backspace": {
  //         if (activeGroup) {
  //           removeModel(activeGroup.model);
  //         }
  //         return;
  //       }
  //       default: {
  //         return;
  //       }
  //     }
  //   };
  //   window.addEventListener("keydown", keyBinding);
  //   return () => window.removeEventListener("keydown", keyBinding);
  // }, [activeGroup, removeModel]);

  const updateLight = useCallback((light) => {
    setLights((prev) => {
      let newLights = [...prev];
      const targetIndex = prev.findIndex((m) => m.id === light.id);
      newLights[targetIndex] = { ...prev[targetIndex], ...light };
      return newLights;
    });
  }, []);

  const loadRandomScene = useCallback(() => {
    const sceneList = Object.keys(scenes);
    const randomScene = sceneList[Math.floor(Math.random() * sceneList.length)];
    setTimeout(() => {
      loadScene(scenes.parasite);
    }, 1000);
    // loadScene(scenes[randomScene]);
  }, [loadScene]);

  useEffect(() => {
    if (scene) {
      loadRandomScene();
    }
  }, [loadRandomScene, scene]);

  const exportScene = useCallback(async () => {
    var json = await JSON.stringify({
      shots: savedShots.map((s) => ({
        ...s,
        models: s.models.map((m) => omit(m, "actions")),
      })),
      config: sceneConfig,
    });

    var blob = new Blob([json], { type: "application/json" });
    var url = URL.createObjectURL(blob);

    var link = document.createElement("a");
    link.href = url;
    link.download = "export-scene.json";
    link.click();
  }, [savedShots, sceneConfig]);

  const removeLight = useCallback((lightIndex) => {
    setLights((prev) => {
      return prev.filter((_, i) => i !== lightIndex);
    });
  }, []);

  const removeShot = useCallback((shotId) => {
    setSavedShots((prev) => prev.filter((p) => p.id !== shotId));
  }, []);

  const value = useMemo(
    () => ({
      loadScene,
      models,
      selectFile,
      removeModel,
      addModel,
      updateModel,
      effectComposerRef,
      activeGroup: !!activeGroup
        ? {
            ...activeGroup,
            model: models.find((p) => p.id === activeGroup?.model.id),
          }
        : activeGroup,
      effectConfig,
      setEffectConfig,
      handleAnimation,
      setSelected,
      meshOnSearch,
      setMeshOnSearch,
      selectMesh,
      updateActiveActions,
      updateActiveMeshProperties,
      updateActiveGroup,
      updateDepthTarget,
      updateActiveModel,
      setScene,
      scene,
      camera,
      setCamera,
      onCameraUpdate,
      // Camera lated
      dollyZoom,
      zoom,
      changeAngle,
      changeShot,
      cameraConfig,
      cancelSelection,
      clearSelected,
      selected,
      setCameraConfig,
      updateProtagonist,
      savedShots,
      addShots,
      sceneConfig,
      setSceneConfig,
      resetAnimation,
      canvasConfig,
      setCanvasConfig,
      lights,
      updateLight,
      setLights,
      focusAtMesh,
      loadShot,
      updateShot,
      exportScene,
      loadRandomScene,
      activeScene,
      removeLight,
      removeShot,
      clearShot,
    }),
    [
      loadScene,
      models,
      selectFile,
      removeModel,
      addModel,
      updateModel,
      activeGroup,
      effectConfig,
      handleAnimation,
      meshOnSearch,
      selectMesh,
      updateActiveActions,
      updateActiveMeshProperties,
      updateActiveGroup,
      updateDepthTarget,
      updateActiveModel,
      scene,
      camera,
      onCameraUpdate,
      dollyZoom,
      zoom,
      changeAngle,
      changeShot,
      cameraConfig,
      cancelSelection,
      clearSelected,
      selected,
      updateProtagonist,
      savedShots,
      addShots,
      sceneConfig,
      resetAnimation,
      canvasConfig,
      lights,
      updateLight,
      focusAtMesh,
      loadShot,
      updateShot,
      exportScene,
      loadRandomScene,
      activeScene,
      removeLight,
      removeShot,
      clearShot,
    ]
  );

  return (
    <SceneContext.Provider value={value}>{children}</SceneContext.Provider>
  );
};

export const useScene = () => React.useContext(SceneContext);
