import React, { useState } from "react";
import styled from "styled-components";
import { useScene } from "../../sceneContext";
import { GeneralButton } from "../Buttons";
import DraggablePanel from "../common/DraggablePanel";
import { Bar } from "../PropsPanel";
import Move from "../svgs/Move";
import Rotate from "../svgs/Rotate";
import Scale from "../svgs/Scale";

const Button = styled(GeneralButton)`
  border: 0px;
  opacity: 0.3;
  padding: 4px;
  & svg {
    height: 20px;
    width: 20px;
  }
  ${({ selected }) =>
    selected &&
    `
      opacity:1;
    
    `}
`;

const Wrapper = styled(Bar)`
  display: flex;
  grid-gap: 8px;
  text-align: left;
  left: 16px;
  bottom: 16px;
  background: #111;
  padding: 4px 8px;
`;

const modes = [
  {
    mode: "translate",
    icon: Move,
  },
  {
    mode: "rotate",
    icon: Rotate,
  },
  {
    mode: "scale",
    icon: Scale,
  },
];
const TransformMode = () => {
  const { effectConfig, setEffectConfig } = useScene();
  return (
    <DraggablePanel defaultPosition={{ x: 0, y: 0 }}>
      <Wrapper>
        {modes.map(({ mode, icon: Icon }) => (
          <Button
            key={mode}
            selected={mode === effectConfig.transformMode}
            onClick={() =>
              setEffectConfig((prev) => ({
                ...prev,
                transformMode: mode,
              }))
            }
          >
            <Icon />
          </Button>
        ))}
      </Wrapper>
    </DraggablePanel>
  );
};
export default TransformMode;
