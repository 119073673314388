import React, { Children, useState } from "react";
import styled from "styled-components";
import ExpandArrow from "../common/ExpandArrow";

export const InputSectionHeader = styled.div`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  padding: 8px 16px;
  &:hover {
    opacity: 0.5;
  }
  & > div:first-of-type {
    flex: 1;
  }
`;

const Content = styled.div`
  padding: 0 16px 8px 16px;
`;

const Section = styled.div`
  // background: #111;
  border-radius: 8px;
  transition: 0.1s ease-out;
  border-radius: 4px;
  background: ${({ expanded }) =>
    expanded ? "rgba(255,255,255, 0.05)" : "transparent"};
`;

const InputSection = ({
  children,
  title,
  expanded: expandedProp,
  component: Component = Section,
  contentComponent: ContentComponent = Content,
  headerComponent: HeaderComponent = InputSectionHeader,
}) => {
  const [expanded, setExpanded] = useState(expandedProp);
  return (
    <Component expanded={expanded}>
      <HeaderComponent
        onClick={() => setExpanded((prev) => !prev)}
        expanded={expanded}
      >
        <div>{title}</div>
        <ExpandArrow expanded={expanded} />
      </HeaderComponent>
      {expanded && !!Children.count(children) && (
        <ContentComponent>{children}</ContentComponent>
      )}
    </Component>
  );
};

export default InputSection;
