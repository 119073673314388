import React from "react";

const Scale = ({
  color = "#fff",
  width = 16,
  height = 16,
  strokeWidth = 10,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="26" width="26" height="26" fill={color} />
      <rect x="4.5" y="4.5" width="49" height="49" stroke={color} />
      <path
        d="M50.5 10C50.5 9.17157 49.8284 8.5 49 8.5H35.5C34.6716 8.5 34 9.17157 34 10C34 10.8284 34.6716 11.5 35.5 11.5H47.5V23.5C47.5 24.3284 48.1716 25 49 25C49.8284 25 50.5 24.3284 50.5 23.5V10ZM36.0607 25.0607L50.0607 11.0607L47.9393 8.93934L33.9393 22.9393L36.0607 25.0607Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
export default Scale;
