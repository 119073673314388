import React from "react";
import { useScene } from "../../sceneContext";
import { InputGroup, InputGroupLabel } from "../Input/InputGroup";
import { shots } from "../../data";
import Select from "../common/Select";

const EditShotCombination = () => {
  const { changeShot, effectConfig } = useScene();

  return (
    <InputGroup flex>
      <InputGroupLabel>Shot</InputGroupLabel>
      <Select
        onChange={(e) => {
          changeShot(e.target.value);
        }}
        value={effectConfig.shot}
      >
        {Object.keys(shots).map((shotKey) => (
          <option key={shotKey} value={shotKey}>
            {shots[shotKey].label}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
};
export default EditShotCombination;
