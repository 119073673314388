import { useFrame, useThree } from "@react-three/fiber";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecorder } from "./recorderContext";
import { useScene } from "./sceneContext";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

const JobsContext = React.createContext();

export const job = {
  screenshot: { key: "screenshot", context: "canvas" },
};
let ffmpeg;

const isEven = (n) => !(n % 2);
const getScale = (width, aspect) => {
  let outputWidth = width;
  let outputHeight = Math.floor(width / aspect);
  if (!isEven(width)) {
    outputWidth -= 1;
    outputHeight = Math.floor(outputWidth / aspect);
  }
  if (!isEven(outputHeight)) {
    outputHeight -= 1;
  }
  return `${outputWidth}:${outputHeight}`;
};

export const JobsProvider = ({ children }) => {
  const gl = useThree((state) => state.gl);
  const scene = useThree((state) => state.scene);
  const camera = useThree((state) => state.camera);
  const { effectComposerRef } = useScene();
  const { recorderJobs, removeJob } = useRecorder();
  const [recordInterval, setRecordInterval] = useState();
  const [startTime, setStartTime] = useState();
  const [frameNumber, setFrameNumber] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRecording, setIsRecording] = useState();
  const [ffmpegLoaded, setFFmpegLoaded] = useState(false);
  const [timeLapsed, setTimeLapsed] = useState(0);

  const renderFrame = useCallback(() => {
    gl.render(scene, camera);
    // // 2. render effects
    effectComposerRef.current.render();
    const screenshot = gl.domElement.toDataURL();
    return screenshot;
  }, [camera, effectComposerRef, gl, scene]);

  const takeScreenshot = useCallback(() => {
    // 1. render scene
    // gl.render(scene, camera);
    // // 2. render effects
    // effectComposerRef.current.render();
    const screenshot = renderFrame();
    const link = document.createElement("a");
    link.setAttribute("download", "cine-screenshot.png");
    link.setAttribute(
      "href",
      screenshot.replace("image/png", "image/octet-stream")
    );
    link.click();
  }, [renderFrame]);

  const record = useCallback(
    async ({ config = {} }) => {
      // setIsRecording({ started: true, config });
      const { fps = 24 } = config;
      ffmpeg = createFFmpeg();

      // const scale = getScale(outputWidth, aspect);
      // console.log(fps, scale);

      await ffmpeg.load();

      // ffmpeg.setProgress(({ ratio }) => {
      //   console.log("save progress", ratio);
      // });

      // let i = 0;
      // setFrameNumber(i);
      // setCurrentTime(0);
      // let frameId;

      // const captureFrame = async (frameIndex) => {
      //   const num = frameIndex.toString().padStart(5, "0");
      //   ffmpeg.FS(
      //     "writeFile",
      //     `tmp.${num}.png`,
      //     await fetchFile(renderFrame())
      //   );
      // };

      // const update = async (timestamp) => {
      //   requestAnimationFrame(update);

      //   if (timestamp - i < 1000 / fps) return;
      //   captureFrame(i);
      //   i++;

      //   i = timestamp;
      // };

      // update(i);
      let i = 0;
      const capture = async (currentIndex) => {
        const num = currentIndex.toString().padStart(5, "0");
        const screenshot = renderFrame();
        ffmpeg.FS("writeFile", `tmp.${num}.png`, await fetchFile(screenshot));
        // const link = document.createElement("a");
        // link.setAttribute("download", `tmp.${num}.png`);
        // link.setAttribute(
        //   "href",
        //   screenshot.replace("image/png", "image/octet-stream")
        // );
        // link.click();

        console.log("currentIndex", currentIndex);
      };

      console.log("start time");
      setStartTime(new Date());

      const intervalId = setInterval(() => {
        capture(i);
        // takeScreenshot();
        // if (i < frames) {
        // const num = i.toString().padStart(5, "0");
        // ffmpeg.FS(
        //   "writeFile",
        //   `tmp.${num}.png`,
        //   await fetchFile(renderFrame())
        // );
        // currentTime += 1 / fps;
        i++;
        setFrameNumber(i);
        // setCurrentTime((prev) => prev + 1 / fps);
        // }
        // console.log("end! clear interval");
        // clearInterval(intervalId);
      }, 1000 / fps);
      setRecordInterval(intervalId);
    },
    [renderFrame, takeScreenshot]
  );
  const endRecord = useCallback(
    async ({ config }) => {
      // setIsRecording({ started: false });
      const { fps = 24, outputWidth, aspect, outputName = "output" } = config;

      const scale = getScale(outputWidth, aspect);
      const fileName = `${outputName}.mp4`;

      // setStartTime((prev) => {
      //   console.log((new Date().getTime() - prev.getTime()) / 1000);
      //   return null;
      // });

      clearInterval(recordInterval);
      setRecordInterval(null);

      await ffmpeg.run(
        "-framerate",
        String(fps),
        "-pattern_type",
        "glob",
        "-i",
        "*.png",
        "-c:v",
        "libx264",
        "-pix_fmt",
        "yuv420p",
        "-preset",
        "slow",
        "-crf",
        String(5),
        "-vf",
        `scale=${scale}`,
        fileName
      );
      const data = ffmpeg.FS("readFile", fileName);

      // // const duration = 1;
      const frames = frameNumber;
      console.log("total frames", frames);

      const save = (blob, filename) => {
        const link = document.createElement("a");
        if (link.href) {
          URL.revokeObjectURL(link.href);
        }
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.dispatchEvent(new MouseEvent("click"));
      };

      for (let i = 0; i < frames; i++) {
        const num = i.toString().padStart(5, "0");
        ffmpeg.FS("unlink", `tmp.${num}.png`);
      }
      save(new Blob([data.buffer], { type: "video/mp4" }), fileName);
      setFrameNumber(0);
    },
    [frameNumber, recordInterval, currentTime]
  );
  const executeJobs = useCallback(
    (job) => {
      removeJob(job.id);

      switch (job.type) {
        case "screenshot": {
          takeScreenshot(job);
          break;
        }
        case "record": {
          console.log(178);
          record(job);
          break;
        }
        case "end-record": {
          endRecord(job);
          break;
        }
        default: {
          break;
        }
      }
    },
    [takeScreenshot, record, endRecord, removeJob]
  );

  useEffect(() => {
    if (recorderJobs[0]) {
      console.log(195);
      executeJobs(recorderJobs[0]);
    }
  }, [recorderJobs]);

  const value = useMemo(() => ({}), []);

  return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
};

export const useJobs = () => React.useContext(JobsContext);
