import React from "react";

const Trash = ({ color = "#fff", width = 16, height = 16, disabled }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.0588 11.3818H60.2713C59.5336 4.98523 54.0913 0 47.5 0C40.9089 0 35.4674 4.98503 34.7297 11.3818H19.9414C13.9569 11.3818 9.08887 16.2512 9.08887 22.2354V22.7923C9.08887 27.3654 11.9363 31.2796 15.9476 32.8744V84.1464C15.9476 90.1308 20.8163 95 26.8003 95H68.2001C74.1843 95 79.0526 90.1304 79.0526 84.1464V32.8746C83.0637 31.2796 85.9113 27.3654 85.9113 22.7925V22.2356C85.9113 16.2512 81.0429 11.3818 75.0588 11.3818ZM47.5 5.1473C51.2488 5.1473 54.3778 7.83427 55.0702 11.3818H39.9312C40.6233 7.83407 43.7526 5.1473 47.5 5.1473ZM73.9053 84.1464C73.9053 87.2927 71.3453 89.8529 68.1999 89.8529H26.8001C23.6547 89.8529 21.0947 87.2925 21.0947 84.1464V33.646H73.9053V84.1464ZM80.764 22.7923C80.764 25.9387 78.204 28.4989 75.0586 28.4989H19.9414C16.7959 28.4989 14.236 25.9387 14.236 22.7923V22.2354C14.236 19.089 16.7959 16.5289 19.9414 16.5289H75.0588C78.2042 16.5289 80.7642 19.089 80.7642 22.2354V22.7923H80.764Z"
        fill={color}
      />
      <path
        d="M33.7018 83.2703C35.1231 83.2703 36.2753 82.1177 36.2753 80.6969V51.7193C36.2753 50.2983 35.123 49.1456 33.7018 49.1456C32.2806 49.1456 31.1282 50.2983 31.1282 51.7193V80.6969C31.128 82.1181 32.2804 83.2703 33.7018 83.2703Z"
        fill={color}
      />
      <path
        d="M47.5 83.2703C48.9214 83.2703 50.0737 82.1177 50.0737 80.6969V51.7193C50.0737 50.2983 48.921 49.1456 47.5 49.1456C46.0788 49.1456 44.9264 50.2983 44.9264 51.7193V80.6969C44.9264 82.1181 46.0786 83.2703 47.5 83.2703Z"
        fill={color}
      />
      <path
        d="M61.2982 83.2703C62.7194 83.2703 63.8716 82.1177 63.8716 80.6969V51.7193C63.8716 50.2983 62.7192 49.1456 61.2982 49.1456C59.8767 49.1456 58.7245 50.2983 58.7245 51.7193V80.6969C58.7243 82.1181 59.877 83.2703 61.2982 83.2703Z"
        fill={color}
      />
    </svg>
  );
};
export default Trash;
