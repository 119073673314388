import React, { useCallback, useMemo, useState } from "react";
import { useScene, getAnimationStatus } from "../sceneContext";
import styled from "styled-components";
import { DeleteButton, SelectButton } from "./Buttons";
import ConfigGroup from "./Input/ConfigGroup";
import Play from "./svgs/Play";
import Pause from "./svgs/Pause";
import PlayButton from "./common/PlayButton";
import Spotlight from "./svgs/Spotlight";
import MeshControl from "./Panel/MeshControl";
import AnimationControls from "./Panel/AnimationControls";
import DraggablePanel from "./common/DraggablePanel";
import ProtagonistStatus from "./Panel/ProtagonistStatus";
import InputSection from "./Input/InputSection";

const OutlineWrapper = styled.div`
  border: 1px solid transparent;
  margin-bottom: 4px;
  border-radius: 4px;
  transition: 0.1s ease-in;
  ${({ isActive }) =>
    isActive &&
    `
  border-color: #666
  `}
`;
const ControlWrapper = styled.div`
  padding: 8px 12px;
`;
const OutlineItem = styled.div`
  padding: 6px 8px;
  border-radius: inherit;
  background: #222;
  width: 100%;
  color: #ccc;
  text-align: left;
  font-size: 14px;
  border: 0;
  font-family: inherit;

  display: flex;
  align-items: center;
  grid-gap: 4px;
`;
const SpotlightButton = styled.button`
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: 0;
  display: flex;
  &:hover {
    opacity: 0.5;
  }
`;
export const OutlinerItem = ({ model }) => {
  const [expanded, setExpanded] = useState(false);
  const {
    setMeshOnSearch,
    activeGroup,
    handleAnimation,
    cameraConfig,
    setCameraConfig,
  } = useScene();

  const isProtagonist = useMemo(
    () => cameraConfig.protagonist === model.id,
    [cameraConfig, model]
  );

  const isActive = useMemo(
    () => activeGroup?.model?.id === model.id,
    [activeGroup, model]
  );
  const onSelectGroup = useCallback(() => {
    setMeshOnSearch(model);
    setExpanded((prev) => !prev);
  }, [setMeshOnSearch, model]);

  return (
    <OutlineWrapper isActive={isActive}>
      <OutlineItem>
        <SelectButton onClick={onSelectGroup} isActive={isActive}>
          {model.name}
        </SelectButton>
        <ProtagonistStatus model={model} />
        {/* {model.actions && (
          <PlayButton
            onClick={() => handleAnimation(model, model.actions[0])}
            isPlaying={model.isAnimating[model.actions[0]]}
          />
        )} */}
      </OutlineItem>
      {/* {isActive && expanded && (
        <DraggablePanel>
          <ControlWrapper>
            <MeshControl model={model} mesh={activeGroup.mesh} />
          </ControlWrapper>
        </DraggablePanel>
      )} */}
    </OutlineWrapper>
  );
};

const OutlinerWrapper = styled.div``;
const Outliner = () => {
  const { models } = useScene();
  return (
    <InputSection title="Objects" expanded component={OutlinerWrapper}>
      {models.map((model) => (
        <OutlinerItem model={model} key={model.id} />
      ))}
    </InputSection>
  );
};
export default Outliner;
