import React from "react";

const Play = ({ color = "#fff", disabled }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 210 210"
    >
      <path fill={color} d="M179.07,105L30.93,210V0L179.07,105z" />
    </svg>
  );
};
export default Play;
