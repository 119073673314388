import * as THREE from "three";
import React, { useMemo, useRef } from "react";

const Room = ({ model, onSelect }) => {
  const ref = useRef();
  const color = useMemo(() => model.color, [model]);

  return (
    <group
      ref={ref}
      name={model.id}
      position={model.position}
      scale={model.scale}
      rotation={model.rotation}
    >
      <mesh>
        <boxGeometry />
        <meshPhysicalMaterial
          side={THREE.DoubleSide}
          color={color}
          roughness={0.7}
        />
      </mesh>
    </group>
  );
};

export default Room;
