import React from "react";

const Rotate = ({
  color = "#fff",
  width = 16,
  height = 16,
  strokeWidth = 5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.86181 32.3879C7.86181 22.8879 11.1981 12.9588 22.3618 8.88787C33.5255 4.81697 39.8618 9.88788 46.8618 17.3879"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.36182 34.9139L14.1353 24.9139H2.58831L8.36182 34.9139Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M50.6479 26.0434C50.5226 35.5426 47.0557 45.4268 35.8393 49.3501C24.6228 53.2735 18.354 48.1194 11.4535 40.5278"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M50.1812 23.511L44.2763 33.434L55.8223 33.5863L50.1812 23.511Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
      <rect x="23" y="23" width="11" height="11" fill={color} />
    </svg>
  );
};
export default Rotate;
