import React from "react";
import BasicModel from "./BasicModel";
import FBXCharacter from "./props/FBX";
import BackgroundPlane from "./props/BackgroundPlane";
import BasicGeometry from "./props/BasicGeometry";
import Room from "./props/Room";

const ModelMesh = (props) => {
  switch (props.model.fileType) {
    case "glb": {
      return <BasicModel {...props} />;
    }
    case "fbx": {
      return <FBXCharacter {...props} />;
    }
    case "plane":
    case "sphere":
    case "cube": {
      return <BasicGeometry {...props} />;
    }
    // case "background": {
    //   return <BackgroundPlane {...props} />;
    // }
    case "room": {
      return <Room {...props} />;
    }
    default: {
      return null;
    }
  }
};

export default ModelMesh;
