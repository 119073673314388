import React from "react";

const Focus = ({ color = "#fff", width = 16, height = 16, disabled }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="29" r="11.5" stroke={color} />
      <line x1="3.5" y1="4" x2="3.5" y2="18" stroke={color} />
      <line x1="3" y1="3.5" x2="17" y2="3.5" stroke={color} />
      <line
        y1="-0.5"
        x2="14"
        y2="-0.5"
        transform="matrix(0 1 1 0 56 4)"
        stroke={color}
      />
      <line
        y1="-0.5"
        x2="14"
        y2="-0.5"
        transform="matrix(-1 0 0 1 56 4)"
        stroke={color}
      />
      <line x1="55.5" y1="54" x2="55.5" y2="40" stroke={color} />
      <line x1="56" y1="54.5" x2="42" y2="54.5" stroke={color} />
      <line
        y1="-0.5"
        x2="14"
        y2="-0.5"
        transform="matrix(0 -1 -1 0 3 54)"
        stroke={color}
      />
      <line
        y1="-0.5"
        x2="14"
        y2="-0.5"
        transform="matrix(1 0 0 -1 3 54)"
        stroke={color}
      />
    </svg>
  );
};
export default Focus;
