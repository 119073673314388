import React from "react";

const Record = ({ color = "#fff", disabled, width = 155, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 155 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.2802 39.6394C73.2802 40.8222 74.2373 41.7819 75.4226 41.7819C76.6063 41.7819 77.5654 40.8222 77.5654 39.6394V31.9558L86.7638 41.1564C87.182 41.572 87.7296 41.7819 88.2786 41.7819C88.8259 41.7819 89.3752 41.572 89.7936 41.1564C90.6306 40.3163 90.6306 38.9623 89.7936 38.1224L80.5952 28.9259H83.9934C87.5368 28.9259 90.4213 26.042 90.4213 22.4979V18.2127C90.4213 14.6687 87.5368 11.7847 83.9934 11.7847H75.4226C74.2373 11.7847 73.2802 12.7448 73.2802 13.9275V26.7835V39.6394ZM94.7066 39.6394C94.7066 40.8222 95.664 41.7819 96.8493 41.7819H109.705C110.889 41.7819 111.848 40.8222 111.848 39.6394C111.848 38.4567 110.889 37.4967 109.705 37.4967H98.9921V28.9259H105.42C106.603 28.9259 107.563 27.9662 107.563 26.7835C107.563 25.6007 106.603 24.6407 105.42 24.6407H98.9921V16.07H109.705C110.889 16.07 111.848 15.1103 111.848 13.9275C111.848 12.7448 110.889 11.7847 109.705 11.7847H96.8493C95.664 11.7847 94.7066 12.7448 94.7066 13.9275V39.6394ZM116.133 35.3539C116.133 38.898 119.016 41.7819 122.561 41.7819H131.132C132.315 41.7819 133.274 40.8222 133.274 39.6394C133.274 38.4567 132.315 37.4967 131.132 37.4967H122.561C121.378 37.4967 120.418 36.5325 120.418 35.3539V18.2127C120.418 17.0342 121.378 16.07 122.561 16.07H131.132C132.315 16.07 133.274 15.1103 133.274 13.9275C133.274 12.7448 132.315 11.7847 131.132 11.7847H122.561C119.016 11.7847 116.133 14.6687 116.133 18.2127V35.3539ZM62.5666 53.5666C56.6593 53.5666 51.8534 48.7627 51.8534 42.8534V10.7135C51.8534 4.80396 56.6593 0 62.5666 0H143.988C149.893 0 154.701 4.80396 154.701 10.7135V42.8534C154.701 48.7627 149.893 53.5666 143.988 53.5666H62.5666ZM83.9934 24.6407H77.5654V16.07H83.9934C85.1751 16.07 86.1361 17.0342 86.1361 18.2127V22.4979C86.1361 23.6765 85.1751 24.6407 83.9934 24.6407Z"
        fill={color}
      />
      <circle cx="17.5" cy="27.5" r="17.5" fill={color} />
    </svg>
  );
};
export default Record;
