import React from "react";

const Spotlight = ({
  color = "#fff",
  disabled,
  width = 24,
  height = 24,
  strokeWidth = 10,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 255 255"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="129"
        cy="127"
        r="47.5"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="129.5"
        x2="129.5"
        y2="46"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="130.5"
        y1="209"
        x2="130.5"
        y2="255"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        y1="125.5"
        x2="46"
        y2="125.5"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="209"
        y1="124.5"
        x2="255"
        y2="124.5"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="38.7581"
        y1="35.9297"
        x2="71.285"
        y2="68.4567"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="187.251"
        y1="183.008"
        x2="219.777"
        y2="215.535"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="219.07"
        y1="38.7582"
        x2="186.543"
        y2="71.2851"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="71.9921"
        y1="187.251"
        x2="39.4652"
        y2="219.778"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
export default Spotlight;
