import * as THREE from "three";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useScene } from "../../sceneContext";
import { InputGroup, InputGroupLabel } from "../Input/InputGroup";
import { angles } from "../../data";
import { DeleteButton, GeneralButton } from "../Buttons";
import FloatPanel from "../common/FloatPanel";
import EditCameraAngle from "./EditCameraAngle";
import EditShotCombination from "./EditShotCombination";
import Trash from "../svgs/Trash";
import { Bar, Seperator } from "../PropsPanel";
import DraggablePanel from "../common/DraggablePanel";
import ExpandArrow from "../common/ExpandArrow";
import Focus from "../svgs/Focus";
import Select from "../common/Select";
import MenuBar from "../common/MenuBar";

// function toScreenPosition(obj, camera, canvas) {
//   var vector = new THREE.Vector3();

//   var widthHalf = 0.5 * canvas.width;
//   var heightHalf = 0.5 * canvas.height;

//   obj.updateMatrixWorld();
//   vector.setFromMatrixPosition(obj.matrixWorld);
//   vector.project(camera);

//   vector.x = vector.x * widthHalf + widthHalf;
//   vector.y = -(vector.y * heightHalf) + heightHalf;

//   return {
//     left: vector.x + canvas.left,
//     top: vector.y + canvas.top,
//   };
// }
// const FloatPanel = styled.div.attrs((props) => ({
//   style: {
//     transform: `
//     translate(${props.left}px, ${props.top}px)
//     translate(-50%, -50%)
//     `,
//   },
// }))`
//   position: absolute;
//   z-index: 1000;
// `;
// const Content = styled.div`
//   position: absolute;
//   bottom: 16px;
//   left: 20px;
//   border-radius: 8px;
//   background: rgba(30, 30, 30, 0.8);
//   display: flex;
//   padding: 8px 16px;
//   width: 300px;
// `;

const Content = styled(MenuBar)`
  background: rgba(30, 30, 30, 0.9);
  padding: 2px 20px;
  grid-gap: 16px;
`;
const QuickMenu = () => {
  const { activeGroup, changeAngle } = useScene();
  const [expanded, setExpanded] = useState(true);

  // const props = useMemo(() => {
  //   if (!activeGroup || !camera) return null;
  //   const pos = toScreenPosition(activeGroup.mesh, camera, canvasConfig);
  //   return {
  //     top: pos.top > window.innerHeight ? window.innerHeight * 0.5 : pos.top,
  //     left:
  //       pos.left + 300 > window.innerWidth
  //         ? window.innerWidth * 0.5 - 150
  //         : pos.left,
  //   };
  // }, [activeGroup, camera]);

  useEffect(() => {
    if (activeGroup) {
      setExpanded(true);
    }
  }, [activeGroup]);
  if (!activeGroup || !expanded) return null;

  return (
    <DraggablePanel
      otherActions={
        <button onClick={() => setExpanded((prev) => !prev)}>
          <ExpandArrow expanded={expanded} />
        </button>
      }
    >
      <Content>
        <EditCameraAngle
          applyCameraAngle={(angle) =>
            changeAngle(angle, activeGroup?.model?.id)
          }
        />
        <EditShotCombination />
      </Content>
    </DraggablePanel>
  );
};
export default QuickMenu;
