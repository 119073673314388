import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import Effects from "./Effects";
import ModelStage from "./ModelStage";
import { useScene } from "../sceneContext";
import LightingStage from "./Stages/LightingStage";

const SceneSetup = () => {
  const scene = useThree((state) => state.scene);
  const camera = useThree((state) => state.camera);
  const { meshOnSearch, models, selectMesh, setCamera, setScene, sceneConfig } =
    useScene();

  useEffect(() => {
    setCamera(camera);
  }, [camera, setCamera]);

  useEffect(() => {
    setScene(scene);
  }, [scene, setScene]);

  // Listen to the searchMesh value
  useEffect(() => {
    if (meshOnSearch) {
      const activeMesh = scene.getObjectByName(meshOnSearch.id, true);
      // if (activeMesh) {
      selectMesh({
        mesh: activeMesh,
        model: { ...meshOnSearch, isSelected: true },
      });
      // }
    }
  }, [meshOnSearch, scene, selectMesh, models]);

  if (!sceneConfig) return null;

  return (
    <>
      <color attach="background" args={[sceneConfig.background]} />
      {/* <ambientLight intensity={1.5} />
      <pointLight
        intensity={50}
        position={[lightPosition.x, lightPosition.y, lightPosition.z]}
        // color="green"
        color="yellow"
      /> */}
      <LightingStage />

      {/* <pointLight intensity={5} position={[-2, 2, 0]} color="green" /> */}
      <group>
        <ModelStage />
      </group>
      <Effects />
    </>
  );
};

export default SceneSetup;
