import React from "react";

const Move = ({ color = "#fff", width = 16, height = 16, disabled }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5814 0L19.8079 10H31.3549L25.5814 0ZM26.5814 20V9H24.5814V20H26.5814Z"
        fill={color}
      />
      <path
        d="M50 25L40 19.2265V30.7735L50 25ZM31.3954 26H41V24H31.3954V26Z"
        fill={color}
      />
      <path
        d="M25.5814 50L31.3549 40H19.8079L25.5814 50ZM24.5814 30V41H26.5814V30H24.5814Z"
        fill={color}
      />
      <path
        d="M-3.62396e-05 25L9.99996 30.7735L9.99996 19.2265L-3.62396e-05 25ZM18.6046 24H8.99996V26H18.6046V24Z"
        fill={color}
      />
    </svg>
  );
};
export default Move;
