import React, { useMemo } from "react";
import { useScene } from "../../sceneContext";
import styled from "styled-components";
import Spotlight from "../svgs/Spotlight";

const SpotlightButton = styled.button`
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: 0;
  display: flex;
  &:hover {
    opacity: 0.5;
  }
`;

const ProtagonistStatus = ({ model }) => {
  const { cameraConfig, setCameraConfig } = useScene();

  const isProtagonist = useMemo(
    () => cameraConfig.protagonist === model.id,
    [cameraConfig, model]
  );

  return (
    <SpotlightButton
      onClick={() => {
        setCameraConfig((prev) => ({ ...prev, protagonist: model.id }));
      }}
    >
      <Spotlight
        width={18}
        height={18}
        color={isProtagonist ? "#ffff00" : "rgba(255,255,255,0.2)"}
      />
    </SpotlightButton>
  );
};

export default ProtagonistStatus;
