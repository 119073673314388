import React, { useState } from "react";
import styled from "styled-components";
import { useScene } from "../../sceneContext";
import DraggablePanel from "../common/DraggablePanel";
import { GeneralButton } from "../Buttons";
import MeshControl from "./MeshControl";
import Close from "../svgs/Close";

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  right: 0;
  box-sizing: content-box;
  scrollbar-gutter: stable;
  display: flex;
  flex-direction: column;
`;

const width = 280;
const Content = styled.div`
  border-radius: 8px;
  width: ${width}px;
`;
const DraggableWrapper = styled.div`
  position: absolute;
  right: ${width * 0.5}px;
`;

const MeshConfigSidebar = () => {
  const [expanded, setExpanded] = useState(true);
  const { activeGroup, canvasConfig, clearSelected } = useScene();
  return (
    <Wrapper expanded={expanded}>
      {/* <Hide onClick={() => setExpanded((prev) => !prev)} expanded={expanded}>
        {expanded ? "Hide" : "Show Tool"}
      </Hide> */}
      {expanded && activeGroup && (
        <DraggableWrapper>
          <DraggablePanel
            defaultPosition={{
              x: -36,
              y: canvasConfig.top + 100,
            }}
            otherActions={
              <GeneralButton
                borderWidth={0}
                onClick={() => {
                  clearSelected();
                }}
              >
                <Close expanded={expanded} />
              </GeneralButton>
            }
          >
            <Content>
              <MeshControl model={activeGroup.model} mesh={activeGroup.mesh} />
            </Content>
          </DraggablePanel>
        </DraggableWrapper>
      )}
    </Wrapper>
  );
};
export default MeshConfigSidebar;
