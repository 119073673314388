import React, { useCallback } from "react";
import { useScene } from "../../sceneContext";
import { InputGroup, InputGroupLabel } from "../Input/InputGroup";
import { angles } from "../../data";
import Select from "../common/Select";

const EditCameraAngle = ({ applyCameraAngle }) => {
  // const { activeGroup, changeAngle } = useScene();

  // const applyCameraAngle = useCallback(
  //   (ap) => {
  //     changeAngle(ap, activeGroup?.model.id);
  //   },
  //   [changeAngle, activeGroup]
  // );

  return (
    <InputGroup flex>
      <InputGroupLabel>Angle</InputGroupLabel>
      <Select onChange={(e) => applyCameraAngle(e.target.value)}>
        {Object.keys(angles).map((angleKey) => (
          <option key={angleKey} value={angleKey}>
            {angles[angleKey].label}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
};
export default EditCameraAngle;
