import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useScene } from "../../sceneContext";
import ColorInput from "../Input/Color";
import InputGridGroup, {
  InputGroup,
  InputGroupLabel,
} from "../Input/InputGroup";
import InputSection from "../Input/InputSection";
import NumberInput from "../Input/Number";
import Slider from "../Input/Slider";
import set from "lodash/set";
import { GeneralButton } from "../Buttons";
import Trash from "../svgs/Trash";

const LightingComponent = styled.div``;
const Content = styled.div``;
const directions = ["x", "y", "z"];

const InputComponent = ({ type, value, onChange }) => {
  switch (type) {
    case "angle": {
      return (
        <Slider
          min={0}
          label="Angle"
          max={Math.PI * 2}
          value={value}
          step={0.1}
          onChange={(v) => onChange(v, "angle")}
        />
      );
    }
    case "intensity": {
      return (
        <Slider
          min={0.1}
          label="Intensity"
          max={20}
          value={value}
          step={1}
          onChange={(v) => onChange(v, "intensity")}
        />
      );
    }
    case "height":
    case "width": {
      return (
        <Slider
          min={0.1}
          max={20}
          step={0.1}
          label={type}
          value={value}
          onChange={(v) => onChange(v, type)}
        />
      );
    }
    case "rotation":
    case "position": {
      return (
        <InputGroup>
          <InputGroupLabel>{type}</InputGroupLabel>
          <InputGridGroup>
            {directions.map((dir, idx) => (
              <NumberInput
                key={dir}
                label={dir}
                value={value[dir]}
                onUpdate={(key, newV) => onChange(newV, `${type}.${dir}`)}
                updateKey={dir}
              />
            ))}
          </InputGridGroup>
        </InputGroup>
      );
    }
    case "color": {
      return (
        <ColorInput
          value={value}
          onUpdate={(color) => onChange(color, "color")}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const lightLabel = {
  pointLight: "Point Light",
  ambient: "Ambient Light",
  spotlight: "Spotlight",
  area: "Area Light",
};
const LightingItem = ({ light, order }) => {
  const { setLights, removeLight } = useScene();

  const attrs = useMemo(
    () =>
      Object.keys(light).reduce((a, c) => {
        if (c === "type") return a;
        return [...a, c];
      }, []),
    [light]
  );

  return (
    <InputSection title={lightLabel[light.type]}>
      {attrs.map((att) => (
        <InputComponent
          key={att}
          type={att}
          value={light[att]}
          onChange={(value, key) => {
            setLights((prev) => {
              let newLights = [...prev];
              set(newLights, `${order}.${key}`, value);
              return newLights;
            });
          }}
        />
      ))}
      <InputGroup>
        <GeneralButton borderWidth={0} onClick={() => removeLight(order)}>
          <Trash />
        </GeneralButton>
      </InputGroup>
    </InputSection>
  );
};

const LightingConfig = () => {
  const { lights } = useScene();

  return (
    <InputSection
      title={`Lighting (${lights.length})`}
      component={LightingComponent}
      contentComponent={Content}
    >
      {lights.map((light, index) => (
        <LightingItem key={index} light={light} order={index} />
      ))}
    </InputSection>
  );
};
export default LightingConfig;
