import React from "react";
import { DepthOfField, EffectComposer } from "@react-three/postprocessing";
import { useScene } from "../sceneContext";

export default function Effects() {
  const { effectComposerRef, effectConfig } = useScene();

  return (
    <EffectComposer ref={effectComposerRef}>
      {effectConfig.enableDepth && (
        <DepthOfField
          focusDistance={effectConfig.focusDistance}
          focalLength={effectConfig.depthFocalLength}
          bokehScale={effectConfig.bokehScale}
          target={[
            effectConfig.depthTarget.x,
            effectConfig.depthTarget.y,
            effectConfig.depthTarget.z,
          ]}
          height={480}
        />
      )}
    </EffectComposer>
  );
}
