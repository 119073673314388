import * as THREE from "three";
import { shaderMaterial } from "@react-three/drei";
import glsl from "babel-plugin-glsl/macro";

// void mainImage( out vec4 fragColor, in vec2 fragCoord )
// {
// 	vec2 uv = fragCoord.xy / iResolution.xy;

//     vec4 color = texture(iChannel0, uv);

//     float strength = 16.0;

//     float x = (uv.x + 4.0 ) * (uv.y + 4.0 ) * (iTime * 10.0);
// 	vec4 grain = vec4(mod((mod(x, 13.0) + 1.0) * (mod(x, 123.0) + 1.0), 0.01)-0.005) * strength;

//     if(abs(uv.x - 0.5) < 0.002)
//         color = vec4(0.0);

//     if(uv.x > 0.5)
//     {
//     	grain = 1.0 - grain;
// 		fragColor = color * grain;
//     }
//     else
//     {
// 		fragColor = color + grain;
//     }
// }
const PlaneShaderMaterial = shaderMaterial(
  {
    uTexture: new THREE.Texture(),
    uPixels: new THREE.Vector2(),
    uScale: new THREE.Vector2(),
    uTime: 0,
  },
  glsl`
      precision mediump float;
      varying vec2 vUv;
    
        void main() {
          vUv = uv;        
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    
        }
    `,
  glsl`
        precision mediump float;
        uniform sampler2D uTexture;
        uniform vec2 uPixels;
        uniform vec2 uScale;
        uniform float uTime;
    
        varying vec2 vUv;
    
  
        vec2 preserveAspectRatioSlice(vec2 uv, vec2 planeSize, vec2 imageSize ){
        
          vec2 ratio = vec2(
              min((planeSize.x / planeSize.y) / (imageSize.x / imageSize.y), 1.0),
              min((planeSize.y / planeSize.x) / (imageSize.y / imageSize.x), 1.0)
          );
          
          
          vec2 sliceUvs = vec2(
              uv.x * ratio.x + (1.0 - ratio.x) * 0.5,
              uv.y * ratio.y + (1.0 - ratio.y) * 0.5
          );
  
          return sliceUvs;
      }
  
  
        void main() {
          vec2 uv = vUv;
          vec2 imageUv = preserveAspectRatioSlice(uv, uScale, uPixels);

          gl_FragColor = texture2D(uTexture, imageUv);
          // gl_FragColor = vec4(texture2D(uTexture, imageUv).rgb, 1.);
        }
      `
);

export default PlaneShaderMaterial;
