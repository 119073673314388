import React from "react";

const Tick = ({ color = "#fff", disabled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      {!disabled && (
        <path
          strokeLinecap="round"
          strokeMiterlimit="10"
          fill="none"
          strokeWidth={2}
          stroke={color}
          d="M22.9 3.7l-15.2 16.6-6.6-7.1"
        />
      )}
    </svg>
  );
};
export default Tick;
