import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { createMeshOptions, models, scenes } from "../data";
import { useScene, makeModel } from "../sceneContext";
import niceColor from "nice-color-palettes";
import { useFilePicker } from "use-file-picker";
import { v4 } from "uuid";

export const Bar = styled.div`
  border-radius: 22px;
  background: ${({ background }) => background};
  display: flex;
  padding: 0 8px;
  justify-content: center;
`;
const Button = styled.button`
  border: 0;
  font-family: inherit;
  cursor: pointer;
  background: transparent;
  color: #fff;
  padding: 8px;
  flex: 1;
  font-size: 12px;
  &:hover {
    opacity: 0.5;
  }
`;
const FileInput = styled.div`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 12px;
  &:hover {
    opacity: 0.5;
  }
`;
export const Seperator = styled.div`
  border-left: 0.5px solid #666;
  margin: 8px 0;
  //   height: 100%;
  align-self: stretch;
`;
const Wrapper = styled.div`
  display: flex;
`;

const getRandomColor = () => {
  const randomColorSet = Math.floor(Math.random() * 100);
  return niceColor[randomColorSet][
    Math.floor(Math.random() * niceColor[randomColorSet].length)
  ];
};

const PropsPanel = () => {
  const { addModel, selectFile, setLights, loadRandomScene, loadScene } =
    useScene();
  const onClick = useCallback(
    (option) => {
      addModel(
        makeModel(
          option.model || {
            ...option,
            fileType: option.geometry,
            color: getRandomColor(),
          }
        )
      );
    },
    [addModel]
  );

  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    accept: ".glb, .fbx, .json",
    readFilesContent: false,
  });

  useEffect(() => {
    if (!!plainFiles.length) {
      selectFile(plainFiles[0], () => clear());
    }
  }, [plainFiles, selectFile, clear]);

  return (
    <Wrapper>
      <Bar background="#111">
        <Button onClick={() => loadScene(scenes.empty)}>Empty Scene</Button>
        <Button onClick={loadRandomScene}>Shuffle Scene</Button>
        <Seperator />
        {createMeshOptions.map((op) => (
          <Button key={op.key} onClick={() => onClick(op)}>
            {op.name}
          </Button>
        ))}
        <Seperator />
        <Button
          onClick={() =>
            setLights((prev) => [
              ...prev,
              {
                type: "ambient",
                intensity: 1,
                color: "#fff",
              },
            ])
          }
        >
          Ambient
        </Button>
        <Button
          onClick={() =>
            setLights((prev) => [
              ...prev,
              {
                type: "spotlight",
                position: { x: 0, y: 2, z: 0 },
                color: "#fff",
                distance: 5,
                intensity: 5,
                angle: Math.PI / 5,
                penumbra: 0.5,
              },
            ])
          }
        >
          Spotlight
        </Button>
        <Button
          onClick={() =>
            setLights((prev) => [
              ...prev,
              {
                type: "pointLight",
                position: { x: -2, y: 2, z: 0 },
                color: "#ffff00",
                intensity: 5,
              },
            ])
          }
        >
          Point Light
        </Button>

        <Button
          onClick={() =>
            setLights((prev) => [
              ...prev,
              {
                type: "area",
                position: { x: -2, y: 2, z: 0 },
                rotation: { x: -2, y: 2, z: 0 },
                color: "#fff",
                intensity: 5,
                width: 10,
                height: 5,
              },
            ])
          }
        >
          Area Light
        </Button>
        <Seperator />
        <FileInput onClick={() => openFileSelector()}>Import</FileInput>
      </Bar>
    </Wrapper>
  );
};
export default PropsPanel;
