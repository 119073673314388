import * as THREE from "three";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  & label {
    cursor: pointer;
  }
`;
const Float = styled.div`
  position: absolute;
  z-index: 10;
  ${({ position }) =>
    Object.keys(position).map((p) => `${p}: ${position[p]}px;`)}
`;
const ColorSample = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #999;
  background: ${({ color }) => color};
  margin-right: 8px;
`;
const arrayToRgb = (colorArray) => ({
  r: colorArray[0] * 255,
  g: colorArray[1] * 255,
  b: colorArray[2] * 255,
});
const getColor = (color) => (Array.isArray(color) ? arrayToRgb(color) : color);
const ColorInput = ({
  value: defaultValue,
  onUpdate,
  position = {
    top: 28,
    left: 12,
  },
}) => {
  const [showColor, setShowColor] = useState(false);
  const ref = useRef();
  const [value, setValue] = useState(getColor(defaultValue));

  useEffect(() => {
    setValue(getColor(defaultValue));
  }, [defaultValue]);

  useEffect(() => {
    const toggle = (e) => {
      e.preventDefault();
      if (ref.current && !ref.current.contains(e.target)) {
        setShowColor(false);
      }
    };
    window.addEventListener("click", toggle);
    return () => window.removeEventListener("click", toggle);
  }, []);

  const onChange = useCallback(
    (color) => {
      onUpdate(color.hex);
      setValue(color.hex);
    },
    [onUpdate]
  );
  const displayValue = useMemo(() => {
    if (value.r !== undefined) {
      const newColor = new THREE.Color(`rgb(${value.r},${value.g},${value.b})`);
      return `#${newColor.getHexString()}`;
    }
    return value;
  }, [value]);

  return (
    <InputWrapper onClick={() => setShowColor((prev) => !prev)} ref={ref}>
      <ColorSample color={value} />
      <label>{displayValue}</label>
      {showColor && (
        <Float position={position}>
          <ChromePicker disableAlpha onChange={onChange} color={value} />
        </Float>
      )}
    </InputWrapper>
  );
};
export default ColorInput;
