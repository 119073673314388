import React from "react";
import styled from "styled-components";
import { useRecorder } from "../../recorderContext";
import { useScene } from "../../sceneContext";
import { GeneralButton } from "../Buttons";
import MenuBar from "../common/MenuBar";
import Select from "../common/Select";
import Checkbox from "../Input/Checkbox";
import { Seperator } from "../PropsPanel";

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const OptionBar = styled(MenuBar)`
  display: flex;
  justify-content: center;
  grid-gap: 16px;
  margin: 8px 0;
  // align-self: center;
  padding: 4px 16px;
  background: rgba(30, 30, 30, 0.9);
`;
const Option = styled.div`
  align-self: center;
`;
const QuickActions = () => {
  const { takeScreenshot } = useRecorder();
  const { effectConfig, setEffectConfig, addShots, savedShots } = useScene();
  return (
    <Wrapper>
      <OptionBar>
        <Checkbox
          label="Overlay"
          value={!effectConfig.hideOverlay}
          onUpdate={(value) => {
            setEffectConfig((prev) => ({
              ...prev,
              hideOverlay: !value,
            }));
          }}
        />
        <Checkbox
          label="Autofocus"
          value={effectConfig.autoFocus}
          disabled={!effectConfig.enableDepth}
          onUpdate={(value) => {
            setEffectConfig((prev) => ({
              ...prev,
              autoFocus: value,
            }));
          }}
        />
        <Seperator />
        <Option>
          <GeneralButton onClick={takeScreenshot} color="#fff">
            Take Screenshot
          </GeneralButton>
        </Option>
      </OptionBar>
    </Wrapper>
  );
};
export default QuickActions;
