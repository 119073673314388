import React from "react";
import { useScene } from "../../sceneContext";
import AspectRatioConfig from "../Panel/camera/AspectRatio";
import ColorInput from "../Input/Color";
import MenuBar, { MenuBarSeperator } from "../common/MenuBar";
import Checkbox from "../Input/Checkbox";
import { GeneralButton } from "../Buttons";
import { useRecorder } from "../../recorderContext";
import { Seperator } from "../PropsPanel";

const MainCanvasPanel = () => {
  const {
    sceneConfig,
    setSceneConfig,
    effectConfig,
    setEffectConfig,
    exportScene,
  } = useScene();
  const { takeScreenshot } = useRecorder();

  if (!sceneConfig) return null;
  return (
    <MenuBar background="#111" padding="2px 24px" centered gap={16}>
      <AspectRatioConfig />
      <MenuBarSeperator />
      <div>
        <ColorInput
          value={sceneConfig?.background}
          position={{ bottom: 24, left: 0 }}
          onUpdate={(color) => {
            setSceneConfig((prev) => ({ ...prev, background: color }));
          }}
        />
      </div>
      <MenuBarSeperator />
      <Checkbox
        value={effectConfig.enableDepth}
        label="Depth"
        onUpdate={(value) => {
          setEffectConfig((prev) => ({
            ...prev,
            enableDepth: value,
          }));
        }}
      />
      <Checkbox
        label="Autofocus"
        value={effectConfig.autoFocus}
        disabled={!effectConfig.enableDepth}
        onUpdate={(value) => {
          setEffectConfig((prev) => ({
            ...prev,
            autoFocus: value,
          }));
        }}
      />

      <Checkbox
        label="Overlay"
        value={!effectConfig.hideOverlay}
        onUpdate={(value) => {
          setEffectConfig((prev) => ({
            ...prev,
            hideOverlay: !value,
          }));
        }}
      />
      <Seperator />
      <GeneralButton onClick={takeScreenshot} color="#fff">
        Take Screenshot
      </GeneralButton>
      <Seperator />

      <GeneralButton borderWidth={0} onClick={exportScene} color="#fff">
        Export
      </GeneralButton>
    </MenuBar>
  );
};

export default MainCanvasPanel;
