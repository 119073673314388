import React from "react";
import { useScene } from "../sceneContext";
import InputGridGroup, {
  InputGroup,
  InputGroupLabel,
} from "./Input/InputGroup";
import NumberInput from "./Input/Number";
import Slider from "./Input/Slider";
import Checkbox from "./Input/Checkbox";
import InputSection from "./Input/InputSection";
import styled from "styled-components";

const directions = ["x", "y", "z"];

const depthOfFieldOptions = [
  {
    key: "bokehScale",
    label: "Bokeh Scale",
    min: 0,
    max: 50,
    step: 1,
  },
  { key: "focusDistance", label: "Focus Distance", min: 0, max: 1, step: 0.01 },
  {
    key: "depthFocalLength",
    label: "Depth Focal Length",
    min: 0,
    max: 1,
    step: 0.001,
  },
];
const options = [
  {
    key: "position",
    label: "Position",
    transformMode: "translate",
    attribute: "position",
  },
  {
    key: "rotation",
    label: "Rotation",
    transformMode: "rotate",
    attribute: "rotation",
  },
];
const CameraComponent = styled.div``;
const Content = styled.div``;

const CameraConfig = () => {
  const {
    zoom,
    camera,
    onCameraUpdate,
    cameraConfig,
    setEffectConfig,
    effectConfig,
    updateDepthTarget,
    dollyZoom,
  } = useScene();

  return (
    <InputSection
      title="Camera"
      component={CameraComponent}
      contentComponent={Content}
    >
      {/* Config here */}
      <InputGroup flex padding="0 16px">
        <Slider
          min={0.1}
          label="Zoom"
          max={20}
          value={cameraConfig.zoom}
          step={1}
          onChange={(value) => zoom(value)}
        />
      </InputGroup>

      <InputGroup flex padding="0 16px">
        <Slider
          min={1}
          label="Dolly Zoom"
          max={128}
          value={cameraConfig.fov}
          step={1}
          onChange={(value) => dollyZoom(value)}
        />
      </InputGroup>

      <InputSection title="Depth of Field">
        <Checkbox
          value={effectConfig.enableDepth}
          label="Enable Depth"
          onUpdate={(value) => {
            setEffectConfig((prev) => ({
              ...prev,
              enableDepth: value,
            }));
          }}
        />
        {depthOfFieldOptions.map((config) => (
          <InputGroup flex key={config.key}>
            <Slider
              label={config.label}
              min={config.min}
              max={config.max}
              value={effectConfig[config.key]}
              updateKey={config.key}
              disabled={!effectConfig.enableDepth}
              step={config.step}
              onChange={(value) =>
                setEffectConfig((prev) => ({
                  ...prev,
                  [config.key]: value,
                }))
              }
            />
          </InputGroup>
        ))}
        <InputSection title="Advanced">
          <InputGroup>
            <InputGroupLabel>Focus Target</InputGroupLabel>
            <InputGridGroup>
              {directions.map((dir) => (
                <NumberInput
                  key={dir}
                  label={dir}
                  value={effectConfig.depthTarget[dir]}
                  onUpdate={(k, newV) => updateDepthTarget({ [dir]: newV })}
                  // updateKey={`${op.attribute}.${dir}`}
                />
              ))}
            </InputGridGroup>
          </InputGroup>
        </InputSection>
      </InputSection>

      {camera && (
        <InputSection title="Manual Control">
          {options.map((op) => (
            <InputGroup key={op.key}>
              <InputGroupLabel>{op.label}</InputGroupLabel>
              <InputGridGroup>
                {directions.map((dir) => (
                  <NumberInput
                    key={dir}
                    label={dir}
                    value={cameraConfig[op.attribute][dir]}
                    onUpdate={onCameraUpdate}
                    updateKey={`${op.attribute}.${dir}`}
                  />
                ))}
              </InputGridGroup>
            </InputGroup>
          ))}
        </InputSection>
      )}
    </InputSection>
  );
};
export default CameraConfig;
