import React from "react";

const Eye = ({ color = "#fff", width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_88)">
        <path
          d="M25 10.0977C15.447 10.0977 6.78378 15.3242 0.391224 23.8135C-0.130408 24.509 -0.130408 25.4807 0.391224 26.1762C6.78378 34.6758 15.447 39.9023 25 39.9023C34.553 39.9023 43.2162 34.6758 49.6088 26.1864C50.1304 25.4909 50.1304 24.5193 49.6088 23.8238C43.2162 15.3242 34.553 10.0977 25 10.0977ZM25.6853 35.494C19.3439 35.8929 14.1071 30.6664 14.506 24.3147C14.8333 19.0779 19.0779 14.8333 24.3147 14.506C30.6561 14.1071 35.8929 19.3336 35.494 25.6853C35.1565 30.9118 30.9118 35.1565 25.6853 35.494ZM25.3682 30.6459C21.952 30.8607 19.1291 28.048 19.3541 24.6318C19.528 21.8088 21.8191 19.528 24.642 19.3439C28.0582 19.1291 30.8812 21.9418 30.6561 25.358C30.472 28.1912 28.1809 30.472 25.3682 30.6459Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5_88">
          <rect width="50" height="50" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Eye;
