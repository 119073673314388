import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useScene } from "../../sceneContext";
import { GeneralButton } from "../Buttons";
import MenuBar from "../common/MenuBar";
import Select from "../common/Select";
import { InputGroup } from "../Input/InputGroup";
import { Seperator } from "../PropsPanel";
import Trash from "../svgs/Trash";

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const OptionBar = styled(MenuBar)`
  margin: 8px 0;
  padding: 2px 20px;
  background: rgba(30, 30, 30, 0.9);
  grid-gap: 16px;
`;

const labels = {
  default: "Save Shot",
  saving: "Saving...",
  saved: "Saved!",
};
const SavedShots = () => {
  const [label, setLabel] = useState(labels.default);

  const {
    savedShots,
    removeShot,
    activeScene,
    addShots,
    loadShot,
    updateShot,
    clearShot,
  } = useScene();
  const [activeShot, setActiveShot] = useState(savedShots[0]?.id);

  useEffect(() => {
    if (activeScene) {
      setActiveShot(activeScene.shots[0]?.id);
    }
  }, [activeScene]);
  useEffect(() => {
    if (!activeShot && savedShots.length) {
      setActiveShot(savedShots[0].id);
    }
  }, [activeShot, savedShots]);

  const onChangeShot = useCallback(
    (shotTarget) => {
      if (!shotTarget) {
        setActiveShot();
        clearShot();
        return;
      }
      loadShot(savedShots.find((p) => p.id === shotTarget));
      setActiveShot(shotTarget);
    },
    [loadShot, savedShots, clearShot]
  );
  const onAddShot = useCallback(() => {
    addShots((newShot) => {
      setActiveShot(newShot.id);
    });
  }, [addShots]);

  const onSaveShot = useCallback(() => {
    setLabel(labels.saving);
    updateShot(activeShot);
    setTimeout(() => {
      setLabel(labels.saved);
      setTimeout(() => {
        setLabel(labels.default);
      }, 1000);
    }, 500);
  }, [activeShot, updateShot]);

  const onRemoveShot = useCallback(() => {
    let target = -1;
    let currentShotOrder = savedShots.findIndex((s) => s.id === activeShot);

    if (currentShotOrder + target < 0) {
      target = +1;
    }
    removeShot(activeShot);
    onChangeShot(savedShots[currentShotOrder + target]?.id);
  }, [removeShot, activeShot, onChangeShot, savedShots]);

  const activeShotConfig = useMemo(
    () => savedShots.find((p) => p.id === activeShot),
    [activeShot, savedShots]
  );

  return (
    <>
      <Wrapper>
        <OptionBar>
          <InputGroup flex>
            <GeneralButton color="#fff" borderWidth={0} onClick={onAddShot}>
              + New Shot
            </GeneralButton>
          </InputGroup>
          {(activeShot || savedShots.length > 0) && (
            <>
              <Seperator />
              <InputGroup flex>
                {savedShots.length > 0 && (
                  <Select
                    maxWidth={200}
                    onChange={(e) => onChangeShot(e.target.value)}
                    value={activeShot}
                  >
                    {savedShots.map((shot) => (
                      <option key={shot.id} value={shot.id}>
                        {shot.name}
                      </option>
                    ))}
                  </Select>
                )}
                {activeShot && (
                  <GeneralButton
                    color="#fff"
                    size="sm"
                    borderWidth={0}
                    onClick={onSaveShot}
                  >
                    {label}
                  </GeneralButton>
                )}
                <GeneralButton borderWidth={0} onClick={onRemoveShot}>
                  <Trash />
                </GeneralButton>
              </InputGroup>
            </>
          )}
        </OptionBar>
      </Wrapper>
      <Wrapper>{activeShotConfig && <h2>{activeShotConfig.name}</h2>}</Wrapper>
    </>
  );
};
export default SavedShots;
