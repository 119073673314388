import styled from "styled-components";

const MenuBar = styled.div`
  border-radius: 22px;
  background: ${({ background }) => background};
  display: flex;
  padding: ${({ padding }) => padding || "0 8px"};
  ${({ centered }) =>
    centered &&
    `
  align-items: center;
    `}
  grid-gap: ${({ gap }) => (gap ? `${gap}px` : "8px")};
`;

export const MenuBarSeperator = styled.div`
  border-left: 0.5px solid #666;
  margin: 8px 0;
  align-self: stretch;
`;

export default MenuBar;
