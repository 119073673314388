import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import React, { useMemo, useRef } from "react";
import ImagePlaneShader from "../../shaders/ImagePlaneShader";
import { extend, useFrame } from "@react-three/fiber";

extend({ ImagePlaneShader });

const ImageTexture = ({ model }) => {
  const image = useTexture(model.texture);
  const ref = useRef();
  useFrame(({ clock }) => {
    ref.current.uTime = clock.getElapsedTime();
  });
  return (
    <imagePlaneShader
      uTexture={image}
      ref={ref}
      transparent={model.transparent}
      uScale={[model.scale[0], model.scale[1]]}
      uPixels={[image.image.naturalWidth, image.image.naturalHeight]}
    />
  );
};

const BackgroundPlane = ({ model }) => {
  const color = useMemo(() => model.color, [model]);
  const ref = useRef();
  // useFrame(({ camera }) => {
  //   // ref.current.rotation.x = camera.rotation.x;
  //   // ref.current.rotation.y = camera.rotation.y;
  //   ref.current.rotation.z = camera.rotation.z;
  //   // ref.current.position.z = camera.position.z + model.position[2];
  // }, []);
  return (
    <mesh
      ref={ref}
      name={model.id}
      position={model.position}
      rotation={model.rotation}
      scale={model.scale}
    >
      <planeGeometry />
      {model.texture ? (
        <ImageTexture model={model} />
      ) : (
        <meshPhysicalMaterial
          wireframe={model.wireframe}
          side={THREE.DoubleSide}
          color={color}
          roughness={0.7}
        />
      )}
    </mesh>
  );
};

export default BackgroundPlane;
