import { SpotLight } from "@react-three/drei";
import React, { memo } from "react";
import { useScene } from "../../sceneContext";

const Lighting = (props) => {
  switch (props.type) {
    case "pointLight": {
      return (
        <pointLight
          intensity={props.intensity}
          position={[props.position.x, props.position.y, props.position.z]}
          // color="green"
          color={props.color}
        />
      );
    }
    case "ambient": {
      return <ambientLight intensity={props.intensity} color={props.color} />;
    }
    case "area": {
      return (
        <rectAreaLight
          color={props.color}
          position={[props.position.x, props.position.y, props.position.z]}
          intensity={props.intensity}
          width={props.width}
          height={props.height}
          rotation={[props.rotation.x, props.rotation.y, props.rotation.z]}
        />
      );
    }
    case "spotlight": {
      return (
        <SpotLight
          castShadow
          color={props.color}
          intensity={props.intensity}
          distance={props.distance}
          angle={props.angle}
          penumbra={props.penumbra}
          position={[props.position.x, props.position.y, props.position.z]}
          // shadow-camera-near={0.1}
          // shadow-camera-far={60}
          // shadow-bias={0.0001}
          // shadow-mapSize-height={2048}
          // shadow-mapSize-width={2048}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const LightingStage = () => {
  const { lights } = useScene();

  return lights?.map((light, idx) => <Lighting key={idx} {...light} />);
};

export default memo(LightingStage);
