import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useScene } from "../../sceneContext";
import Checkbox from "./Checkbox";
import ColorInput from "./Color";
import { InputGroup } from "./InputGroup";
import SelectTexture from "./SelectTexture";

const SwitchMode = styled.button`
  color: #888;
  font-size: 10px;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: #888;
  }
`;
const Sub = styled.div`
  display: flex;
  align-items: center;
`;
const SelectMaterial = ({ model }) => {
  const { updateActiveModel } = useScene();
  const [mode, setMode] = useState("color");

  useEffect(() => {
    setMode(model.texture ? "texture" : "color");
  }, [model]);

  //   Todo: switch
  const disableTexture = !model.enableTexture;

  if (mode === "color") {
    if (!model.disableColor) {
      return (
        <InputGroup>
          <ColorInput
            value={model.color}
            onUpdate={(color) =>
              updateActiveModel({
                ...model,
                color,
              })
            }
            label="Color"
          />
          {!disableTexture && (
            <SwitchMode onClick={() => setMode("texture")}>
              Switch to Texture
            </SwitchMode>
          )}
        </InputGroup>
      );
    }
    return null;
  }

  return (
    <InputGroup>
      <SelectTexture
        value={model.texture}
        onUpdate={(texture) => {
          // console.log(texture, model);
          updateActiveModel({
            ...model,
            texture: texture,
          });
        }}
      />
      <Sub>
        <Checkbox
          value={model.preserveAspectRatio}
          label="Fixed Aspect Ratio"
          size={16}
          onUpdate={(value) => {
            updateActiveModel({
              ...model,
              preserveAspectRatio: value,
            });
          }}
        />
      </Sub>

      {!model.disableColor && (
        <SwitchMode onClick={() => setMode("color")}>
          Switch to Color
        </SwitchMode>
      )}
    </InputGroup>
  );
};
export default SelectMaterial;
