import styled from "styled-components";

const FloatPanel = styled.div`
  position: absolute;
  top: ${({ top }) => top || "24px"};
  left: ${({ left }) => left || "50%"};
  height: 0;
  transform: translateX(-50%);
  ${({ right }) =>
    right &&
    `
    left: auto;
    right: ${right};
    transform: none;
  `};
  ${({ bottom }) =>
    bottom &&
    `
    top: auto;
    bottom: ${bottom};
  `};
  ${({ transformTop }) =>
    transformTop &&
    `
  transform: translate(-50%, ${transformTop});

`};
  z-index: ${({ zIndex }) => zIndex || 1000};
`;
export default FloatPanel;
