import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { GeneralButton } from "./Buttons";

import CameraConfig from "./CameraConfig";
import DraggablePanel from "./common/DraggablePanel";
import ExpandArrow from "./common/ExpandArrow";
import Outliner from "./Outliner";
import LightingConfig from "./Panel/LightingConfig";

const drawerWidth = 280;

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  height: 100vh;
  box-sizing: content-box;
  scrollbar-gutter: stable;
  display: flex;
  flex-direction: column;
  // @media (min-width: 769px) {
  //   padding-top: 8px;
  //   height: ${({ expanded }) => (expanded ? "100vh" : "auto")};
  //   overflow-y: auto;
  // }
  // @media (max-width: 768px) {
  //   width: 100%;
  //   max-height: 200px;
  //   padding: 16px;
  //   top: 0;
  //   overflow: auto;
  //   background: #000000;
  // }
`;

const Hide = styled(GeneralButton)`
  color: #999;
  font-size: 12px;
  border: 0;
  white-space: nowrap;
  &:hover {
    color: #fff;
  }
`;
const HideActionWrapper = styled.div`
  flex: 1;
`;
const SideBarOptions = styled.div`
  width: ${drawerWidth}px;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
`;
const SideBarOption = styled.div`
  background: #111;
  border-radius: 8px;
`;
const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Wrapper expanded={expanded}>
      <DraggablePanel
        defaultPosition={{ x: drawerWidth * 0.5 + 16, y: 0 }}
        otherActions={
          <HideActionWrapper>
            <Hide
              onClick={() => setExpanded((prev) => !prev)}
              expanded={expanded}
            >
              <ExpandArrow expanded={expanded} />
            </Hide>
          </HideActionWrapper>
        }
      >
        <SideBarOptions>
          {expanded && (
            <>
              <SideBarOption>
                <Outliner />
              </SideBarOption>
              <SideBarOption>
                <CameraConfig />
              </SideBarOption>
              <SideBarOption>
                <LightingConfig />
              </SideBarOption>
            </>
          )}
        </SideBarOptions>
      </DraggablePanel>
    </Wrapper>
  );
};
export default Sidebar;
