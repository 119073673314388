import gsap from "gsap";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecorder } from "../recorderContext";
import { useScene } from "../sceneContext";

const safeZonePercentage = 0.95;

const CameraFrame = ({ updateMaskLocation, containerRef }) => {
  const { sceneConfig } = useScene();
  const cameraConfig = useMemo(() => sceneConfig?.camera, [sceneConfig]);

  const ref = useRef();
  const [viewBox, setViewBox] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const resize = useCallback(() => {
    let offset = { left: 0, top: 0 };
    if (containerRef.current) {
      const {
        width: newWidth,
        height,
        left,
        top,
      } = containerRef.current.getBoundingClientRect();
      setViewBox({ width: newWidth, height });
      offset.left = left;
      offset.top = top;
    }
    const dim = ref.current.getBoundingClientRect();
    if (!dim.width) {
      updateMaskLocation?.({
        height: window.innerHeight,
        width: window.innerWidth,
        left: 0,
        top: 0,
      });
    } else {
      updateMaskLocation?.({
        height: dim.height,
        width: dim.width,
        left: dim.left - offset.left,
        top: dim.top - offset.top,
      });
    }
  }, [containerRef, updateMaskLocation]);

  useLayoutEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [resize]);

  const frameDimension = useMemo(() => {
    if (!cameraConfig) return {};
    const frameWidth = viewBox.width * safeZonePercentage;
    const frameHeight = frameWidth / cameraConfig.aspect;

    const dim = {
      width: frameWidth,
      height: frameHeight,
      x: (viewBox.width - frameWidth) * 0.5,
      y: (viewBox.height - frameHeight) * 0.5,
    };

    if (dim.height > viewBox.height) {
      dim.height = viewBox.height * safeZonePercentage;
      dim.width = dim.height * cameraConfig.aspect;
      dim.x = (viewBox.width - dim.width) * 0.5;
      dim.y = (viewBox.height - dim.height) * 0.5;
    }
    return dim;
  }, [viewBox, cameraConfig]);

  useEffect(() => {
    if (cameraConfig?.aspect) {
      resize();
    }
  }, [cameraConfig, resize]);

  return (
    <>
      <svg
        width={viewBox.width}
        height={viewBox.height}
        // height={windowWidth / config.aspect}
        viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
        // viewBox={`0 0 ${width} ${width / config.aspect}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Camera frame */}
        <rect
          x={frameDimension.x}
          y={frameDimension.y}
          height={frameDimension.height}
          width={frameDimension.width}
          stroke="#ececec"
          //   stroke="#444"
          strokeWidth={1}
          ref={ref}
        />
      </svg>
    </>
  );
};
export default CameraFrame;
