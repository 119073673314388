import React, { useState } from "react";
import styled from "styled-components";

const Header = styled.div`
  color: #fff;
  border: 0;
  width: 100%;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  padding: 16px 16px;
  //   background: rgba(255, 255, 255, 0.5);
  //   margin-bottom: 16px;
  &:hover {
    opacity: 0.5;
  }
`;
const Group = styled.div`
  @media (min-width: 769px) {
    background: #111;
    margin: 8px;
    border-radius: 12px;
  }
`;
const Content = styled.div`
  padding: 4px 16px 12px 16px;
`;

const ConfigGroup = ({ children, title, expanded: expandedProp }) => {
  const [expanded, setExpanded] = useState(expandedProp);
  return (
    <Group>
      <Header onClick={() => setExpanded((prev) => !prev)}>{title}</Header>
      {expanded && !!children.length && <Content>{children}</Content>}
    </Group>
  );
};

export default ConfigGroup;
