import React from "react";
import { aspectRatios } from "../../../data";
import { useScene } from "../../../sceneContext";
import { GeneralButton } from "../../Buttons";
import { InputGroup } from "../../Input/InputGroup";

const AspectRatioConfig = () => {
  const { setSceneConfig, sceneConfig } = useScene();
  return (
    <InputGroup flex>
      {aspectRatios.map((ap) => (
        <GeneralButton
          key={ap.key}
          selected={sceneConfig.camera.aspect === ap.value}
          onClick={() =>
            setSceneConfig((prev) => ({
              ...prev,
              camera: { ...prev.camera, aspect: ap.value },
            }))
          }
        >
          {ap.name}
        </GeneralButton>
      ))}
    </InputGroup>
  );
};
export default AspectRatioConfig;
