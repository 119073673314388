import { Select, useFBX } from "@react-three/drei";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAnimations } from "@react-three/drei";
import { useScene } from "../../sceneContext";
import * as THREE from "three";
import { v4 } from "uuid";

const FBX = ({ model, onSelect }) => {
  const fbx = useFBX(model.src);
  const { updateModel } = useScene();
  const { animations } = fbx;

  const group = useRef();
  const primitiveRef = useRef();
  const [baseGeometries, setBaseGeometries] = useState([]);

  //   useEffect(() => {
  //     if (fbx) {
  //       console.log(fbx);
  //       setBaseGeometries([]);

  //       fbx.traverse((child) => {
  //         if (child.isMesh) {
  //           //   let o = child.clone();
  //           //   o.scale = new THREE.Vector3(
  //           //     model.loadScale,
  //           //     model.loadScale,
  //           //     model.loadScale
  //           //   );
  //           //   setBaseGeometries((prev) => [...prev, o]);
  //           //   let o = child.clone();
  //           //   console.log(child);
  //           //   o.scale(model.loadScale, model.loadScale, model.loadScale);
  //           //   let newGeo = o.geometry.clone();
  //           //   newGeo.scale(model.loadScale, model.loadScale, model.loadScale);
  //         }
  //       });
  //     }
  //   }, [fbx, model]);

  useEffect(() => {
    fbx.traverse((child) => {
      if (child.isMesh) {
        child.material.wireframe = model.wireframe;
        child.material.color?.set(model.color);
      }
    });
  }, [model.wireframe, model.color, fbx]);

  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    if (!model.actions) {
      updateModel({ ...model, actions });
    }
  }, [model?.actions]);

  return (
    <Select
      box
      onChange={(e) => {
        const selected = !!e.length ? [group.current] : [];
        // const selected = !!e.length ? [fbx] : [];
        // console.log(fbx, group.current);
        onSelect({
          mesh: selected,
          actions,
          model,
        });
      }}
    >
      <group
        ref={group}
        key={model.id}
        name={model.id}
        position={model.position}
        rotation={model.rotation}
        scale={model.scale}
      >
        {/* {baseGeometries.map((b) => (
          <primitive object={b} key={b.uuid} />
        ))} */}
        <primitive
          dispose={null}
          object={fbx}
          ref={primitiveRef}
          scale={[model.loadScale, model.loadScale, model.loadScale]}
        />
      </group>
    </Select>
  );
};

export default FBX;
