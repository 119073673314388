import React from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import Grab from "../svgs/Grab";
import FloatPanel from "./FloatPanel";

const GrabHandle = styled.div`
  text-align: center;
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  grid-gap: 8px;
  &:hover {
    opacity: 1;
  }
`;
const DraggablePanel = ({
  children,
  otherActions,
  defaultPosition = { x: 0, y: 0 },
}) => (
  <FloatPanel>
    <Draggable
      handle=".handle"
      defaultPosition={defaultPosition}
      position={null}
      scale={1}
    >
      <div>
        <GrabHandle className="handle">
          <Grab />
          {otherActions}
        </GrabHandle>
        {children}
      </div>
    </Draggable>
  </FloatPanel>
);
export default DraggablePanel;
