import React from "react";

const Close = ({
  color = "#fff",
  width = 16,
  height = 16,
  strokeWidth = 4,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="8.35355"
        y1="7.64645"
        x2="51.3536"
        y2="50.6464"
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <line
        x1="7.64645"
        y1="50.6464"
        x2="50.6464"
        y2="7.64645"
        strokeWidth={strokeWidth}
        stroke={color}
      />
    </svg>
  );
};
export default Close;
