import React, { useMemo, useState } from "react";
import { useScene } from "../../sceneContext";
import PlayButton from "../common/PlayButton";
import styled from "styled-components";
import sortBy from "lodash/sortBy";
import Checkbox from "../Input/Checkbox";
import { GeneralButton } from "../Buttons";
import Slider from "../Input/Slider";

const AnimationItem = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 4px;
  font-size: 12px;
  margin: 4px 10px 0 4px;
`;

const PlayGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  &:hover {
    opacity: 0.5;
  }
  color: ${({ isPlaying }) => (isPlaying ? "#fff" : "#777")};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const lineHeight = 30;

const ListWrapper = styled.div`
  height: ${({ count = 4 }) =>
    (count > 4 ? 4 : count) * lineHeight + lineHeight * 0.25}px;
  overflow: auto;
`;

const AnimationControls = ({ model }) => {
  const { handleAnimation, updateModel, resetAnimation } = useScene();
  const [timeScale, setTimeScale] = useState(1);

  const orderedActions = useMemo(() => {
    if (!model.actions) return [];

    return sortBy(
      Object.keys(model.actions).map((a) => ({
        displayName: a.replace(model.namespace, ""),
        key: a,
        action: model.actions[a],
      })),
      (e) => e.displayName
    );
  }, [model]);
  if (!model.actions) return null;

  return (
    <Wrapper>
      <Slider
        min={0}
        label="Speed"
        step={0.01}
        max={1}
        value={timeScale}
        onChange={(value) => setTimeScale(value)}
      />

      <ListWrapper count={orderedActions.length}>
        {orderedActions.map((a, idx) => {
          const isPlaying = model.isAnimating[a.key];
          return (
            <AnimationItem key={idx}>
              <PlayGroup
                onClick={() => handleAnimation(model, a.key, { timeScale })}
                isPlaying={isPlaying}
              >
                <PlayButton isPlaying={isPlaying} />
                {a.displayName}
              </PlayGroup>
              <GeneralButton
                onClick={() => resetAnimation(model, a.action, a.key)}
                size="sm"
                color={isPlaying ? "#fff" : "#777"}
                disabled={!isPlaying}
              >
                reset
              </GeneralButton>
            </AnimationItem>
          );
        })}
      </ListWrapper>
      <Checkbox
        value={!!model.enableBlend}
        label="Enable Animation Blend"
        onUpdate={(value) => {
          updateModel({ ...model, enableBlend: value });
        }}
      />
    </Wrapper>
  );
};
export default AnimationControls;
