import * as THREE from "three";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import { useScene } from "../../sceneContext";
import { useFilePicker } from "use-file-picker";
import Checkbox from "./Checkbox";

const TextureSample = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #999;
  background: rgba(255, 255, 255, 0.9);
  margin-right: 8px;
`;

const InputWrapper = styled.div`
  align-items: center;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
`;

const SelectTexture = ({ value: defaultValue, onUpdate }) => {
  const [value, setValue] = useState(defaultValue);

  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    accept: ".png,.jpeg,.jpg",
    readFilesContent: false,
  });

  const selectFile = useCallback(
    (file) => {
      const url = URL.createObjectURL(file);
      onUpdate(url);
      setValue(file.name);
      clear();
    },
    [onUpdate, clear]
  );

  useEffect(() => {
    if (!!plainFiles.length) {
      //   console.log(plainFiles);
      selectFile(plainFiles[0]);
    }
  }, [plainFiles, selectFile]);

  return (
    <InputWrapper onClick={() => openFileSelector()} alt={value}>
      <Checkbox label="Selected Texture" value={true} />
    </InputWrapper>
  );
};
export default SelectTexture;
