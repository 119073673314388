import React, { useCallback } from "react";
import { useScene } from "../../sceneContext";
import NumberInput from "../Input/Number";
import set from "lodash/set";
import styled from "styled-components";
import { GeneralButton } from "../Buttons";
import InputGridGroup, {
  InputGroup,
  InputGroupLabel,
} from "../Input/InputGroup";
import Checkbox from "../Input/Checkbox";
import AnimationControls from "./AnimationControls";
import InputSection, { InputSectionHeader } from "../Input/InputSection";
import Trash from "../svgs/Trash";
import Eye from "../svgs/Eye";
import ProtagonistStatus from "./ProtagonistStatus";
import SelectMaterial from "../Input/SelectMaterial";
import Focus from "../svgs/Focus";
import gsap from "gsap";

const directions = ["x", "y", "z"];

const options = [
  {
    key: "position",
    label: "Position",
    transformMode: "translate",
    attribute: "position",
  },
  {
    key: "rotation",
    label: "Rotation",
    transformMode: "rotate",
    attribute: "rotation",
  },
  {
    key: "scale",
    label: "Scale",
    transformMode: "scale",
    attribute: "scale",
  },
];

const MainActions = styled.div`
  display: flex;
  grid-gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: rgba(30, 30, 30, 0.9);
  border-radius: 8px;
  margin-top: 8px;
`;

const StyledGroup = styled.div`
  transition: 0.1s ease-out;
  border-radius: 4px;
  margin-bottom: 8px;
  background: ${({ expanded }) =>
    expanded ? "rgba(255,255,255, 0.05)" : "transparent"};
`;
const OuterDiv = styled.div``;

const Main = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: rgba(30, 30, 30, 0.9);
`;
const Wrapper = styled.div``;

const Header = styled(InputSectionHeader)`
  background: rgba(30, 30, 30, 0.9);
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    opacity: 1;
  }
  ${({ expanded }) =>
    expanded &&
    `
    border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  `}
`;
const Actions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  grid-gap: 16px;
`;

const ControlTitle = ({ model }) => {
  const { focusAtMesh } = useScene();

  return (
    <div style={{ display: "flex", alignItems: "center", gridGap: 8 }}>
      {model.name}
      <GeneralButton
        borderWidth={0}
        onClick={(e) => {
          e.stopPropagation();
          focusAtMesh(model.id);
        }}
      >
        <Focus />
        Locate
      </GeneralButton>
    </div>
  );
};
const MeshControl = ({ model, mesh }) => {
  const {
    updateActiveMeshProperties,
    removeModel,
    updateModel,
    updateDepthTarget,
    handleAnimation,
  } = useScene();

  const onMeshUpdate = useCallback(
    (updateKey, newValue) => {
      if (!mesh) return;
      set(mesh, updateKey, newValue);
      updateActiveMeshProperties(mesh);
    },
    [mesh, updateActiveMeshProperties]
  );
  const testMovement = useCallback(() => {
    const tl = gsap.timeline();
    handleAnimation(model, "Armature|01_walk", {
      timeScale: 0.75,
      fromStart: true,
    });
    tl.set(mesh.position, { y: 1.5, z: 4.4 });
    tl
      // .to(mesh.position, {
      //   y: 1.7,
      //   z: 4.6,
      //   duration: 0.5,

      //   // ease: "steps(20)",
      // })
      .to(mesh.position, {
        // delay: 0.5,
        y: 1.9,

        z: 4.8,
        duration: 2,
        ease: "rough({ strength: 1, points: 20, template: none.out, taper: none, randomize: true, clamp: false })",
        // ease: "steps(20)",
      });
  }, [model, mesh]);

  if (!model) return null;
  return (
    <InputSection
      title={<ControlTitle model={model} />}
      expanded
      headerComponent={Header}
      component={Wrapper}
      contentComponent={OuterDiv}
    >
      <Main>
        <InputSection title="Look & Feel" component={StyledGroup} expanded>
          <Actions>
            <SelectMaterial model={model} />
            <InputGroup>
              <Checkbox
                value={!!model.wireframe}
                label="Wireframe"
                onUpdate={(value) => {
                  updateModel({ ...model, wireframe: value });
                }}
              />
            </InputGroup>
          </Actions>
        </InputSection>
        <InputSection title="Movement" component={StyledGroup} expanded>
          <GeneralButton onClick={testMovement}>Walk Up</GeneralButton>
        </InputSection>
        {model.actions && (
          <InputSection title="Animations" component={StyledGroup} expanded>
            <AnimationControls model={model} />
          </InputSection>
        )}
        {mesh && (
          <InputSection title="Manual Control" component={StyledGroup}>
            {options.map((option) => (
              <InputGroup key={option.key}>
                <InputGroupLabel>{option.label}</InputGroupLabel>
                <InputGridGroup>
                  {directions.map((dir) => (
                    <NumberInput
                      key={dir}
                      label={dir}
                      value={mesh[option.attribute][dir]}
                      onUpdate={onMeshUpdate}
                      updateKey={`${option.attribute}.${dir}`}
                    />
                  ))}
                </InputGridGroup>
              </InputGroup>
            ))}
          </InputSection>
        )}
      </Main>

      <MainActions>
        <GeneralButton
          borderWidth={0}
          onClick={() => {
            updateDepthTarget({
              x: mesh.position.x,
              y: mesh.position.y,
              z: mesh.position.z,
            });
          }}
        >
          <Focus />
        </GeneralButton>
        <ProtagonistStatus model={model} />
        <Checkbox
          value={model.visible}
          icon={Eye}
          border="0"
          onUpdate={(value) => {
            mesh.visible = value;
            const newModel = { ...model, visible: value };
            // updateActiveGroup({ mesh, model: newModel });
            updateModel(newModel);
          }}
        />
        <GeneralButton borderWidth={0} onClick={() => removeModel(model)}>
          <Trash />
        </GeneralButton>
      </MainActions>
    </InputSection>
  );
};
export default MeshControl;
