import styledComponents from "styled-components";

const Select = styledComponents.select`
  font-family: inherit;
  padding: 4px 16px 4px 8px;
  outline: none;
  background: transparent;
  color: #fff;
  border-radius: 4px;
  max-width: ${({ maxWidth }) => `${maxWidth}px` || "auto"};
`;
export default Select;
