import { Html, TransformControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useScene } from "../sceneContext";
import ModelMesh from "./ModelMesh";
import some from "lodash/some";
import DraggablePanel from "./common/DraggablePanel";

const ModelStage = () => {
  const {
    models,
    effectConfig,
    setSelected,
    updateActiveMeshProperties,
    updateActiveGroup,
    activeGroup,
    updateModel,
    clearSelected,
  } = useScene();
  const ref = useRef();
  const scene = useThree((state) => state.scene);

  const onSelectChange = useCallback(
    ({ mesh, model }) => {
      const activeMesh = scene.getObjectByName(model.id, true);
      const updatedModel = { ...model, isSelected: !!mesh.length };
      updateModel(updatedModel);

      if (!!mesh.length) {
        updateActiveGroup({ model: updatedModel, mesh: activeMesh });
        setSelected(mesh);
      }
    },
    [scene, setSelected, updateActiveGroup, updateModel]
  );

  useEffect(() => {
    const hasSelected = some(models, (m) => m.isSelected);
    if (!hasSelected) {
      clearSelected();
    }
  }, [models]);

  const onObjectChange = useCallback(
    (e) => {
      if (e) {
        updateActiveMeshProperties(activeGroup.mesh);
      }
    },
    [activeGroup, updateActiveMeshProperties]
  );
  return (
    <>
      {!!activeGroup &&
        activeGroup?.model?.visible &&
        !effectConfig.hideOverlay && (
          <TransformControls
            ref={ref}
            object={activeGroup.mesh}
            mode={effectConfig.transformMode}
            onObjectChange={onObjectChange}
          />
        )}
      {models.map((m) => (
        <ModelMesh key={m.id} model={m} onSelect={onSelectChange} />
      ))}
    </>
  );
};
export default ModelStage;
