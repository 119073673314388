import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const Number = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: inherit;
  flex: 1 1 0%;
  & input {
    outline: none;
    font-size: 12px;
    font-weight: inherit;
    color: inherit;
    font-family: inherit;
    border: none;
    padding: 4px;
    background-color: #444;
    border-radius: 4px;
    appearance: none;
    width: 0px;
    min-width: 0px;
    flex: 1 1 0%;
    height: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  & input[type="range"] {
    flex: 1;
  }

  & label {
    width: 30px;
    font-size: 12px;
    padding: 4px;
  }
`;
const SliderLabel = styled.div`
  width: 50px;
  font-size: 12px;
  ${({ disabled }) => disabled && `opacity: .5;`}
`;
const Slider = ({
  min,
  max,
  value: defaultValue,
  step,
  onChange: onChangeProp,
  label,
  disabled,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = useCallback(
    (v) => {
      const value = parseFloat(v.target.value);
      onChangeProp(value);
      setValue(value);
    },
    [onChangeProp]
  );

  return (
    <Container>
      {label && <SliderLabel disabled={disabled}>{label}</SliderLabel>}
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <Number>
        <input
          type="number"
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Number>

      {/* <label>{value}</label> */}
    </Container>
  );
};
export default Slider;
