import { Canvas, useThree } from "@react-three/fiber";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { keyframes } from "styled-components";
import { RecorderProvider, useRecorder } from "../recorderContext";
import { SceneProvider, useScene } from "../sceneContext";
import SceneSetup from "./SceneSetup";
import PropsPanel, { Bar } from "./PropsPanel";
import Sidebar from "./Sidebar";
import QuickActions from "./Panel/QuickActions";
import { JobsProvider } from "../jobsContext";
import gsap, { SteppedEase } from "gsap";
import { scenes } from "../data";
import CameraFrame from "../svgs/CameraFrame";
import Record from "./svgs/Record";
import Plus from "./svgs/Plus";
import AspectRatioConfig from "./Panel/camera/AspectRatio";
import QuickMenu from "./Panel/QuickMenu";
import ColorInput from "./Input/Color";
import { InputGroup } from "./Input/InputGroup";
import DraggablePanel from "./common/DraggablePanel";
import MeshControl from "./Panel/MeshControl";
import MeshConfigSidebar from "./Panel/MeshConfigSidebar";
import FloatPanel from "./common/FloatPanel";
import MenuBar, { MenuBarSeperator } from "./common/MenuBar";
import { GeneralButton } from "./Buttons";
import TransformMode from "./Panel/TransformMode";
import Checkbox from "./Input/Checkbox";
import SavedShots from "./Panel/SavedShots";
import MainCanvasPanel from "./Panel/MainCanvasPanel";
import { Html } from "@react-three/drei";

const PageWrapper = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const blink = keyframes`
  0% {
opacity: 1;
  }
50% {opacity: .5}
  100 {
    opacity: 1;
  }
`;
const Loading = styled.div`
  background: #ff454b;
  color: #fff;
  display: flex;
  font-size: 14px;
  border: 1px solid #999;
  border-radius: 16px;
  padding: 4px 8px;
  margin-bottom: 8px;
  justify-content: center;
  animation: ${blink} 1.5s linear infinite;
`;
const CanvasWrapper = styled.div`
  background: #111;
  position: absolute;
  ${({ position }) => `
  top: ${position.top}px;
  left: ${position.left}px;
  height: ${position.height}px;
  width: ${position.width}px;
  `}
`;

const CenterWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CameraActions = styled.div`
  // display: flex;
  // align-items: center;
  // grid-gap: 16px;
  text-align: right;
`;
const RecordButton = styled.button`
  cursor: pointer;

  transition: 0.1s ease-in;
  &:hover {
    fill-opacity: 0.5;
  }
  & svg {
    width: 100px;
  }
`;
const TimeLapsed = styled.div`
  color: #fff;
  font-family: inherit;
  font-size: 18px;
  margin-bottom: 8px;
`;
const Content = ({ canvasRef }) => {
  const { startRecording, stopRecording, start, timeLapsed, saving } =
    useRecorder();

  const { sceneConfig, canvasConfig, setCanvasConfig } = useScene();
  const containerRef = useRef();

  // const [position, setPosition] = useState({
  //   height: 0,
  //   width: 0,
  //   top: 0,
  //   left: 0,
  // });
  // console.log("position", position);

  const onRecord = useCallback(() => {
    if (!start) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [start, startRecording, stopRecording]);

  const onPointerDown = useCallback((e) => {
    console.log(e);
    switch (e.button) {
      case 2: {
        console.log("right click", e);
        break;
      }
      default: {
        return;
      }
    }
  }, []);
  return (
    <PageWrapper>
      <Sidebar />
      <MeshConfigSidebar />

      <FloatPanel>
        <PropsPanel />
        <SavedShots />
        {/* <QuickActions /> */}
      </FloatPanel>
      {/* <FloatPanel right="24px">
        <SavedShots />
      </FloatPanel> */}

      <FloatPanel
        right="48px"
        // right={`${canvasConfig.left}px`}
        top={`${canvasConfig.top + 16}px`}
      >
        <CameraActions>
          {saving ? (
            <Loading>Converting to mp4</Loading>
          ) : (
            <RecordButton onClick={() => onRecord?.()} isRecording={start}>
              <Record color={saving || !start ? "#fff" : "#ff454b"} />
            </RecordButton>
          )}
          {start && <TimeLapsed>{timeLapsed.toFixed(2)}s</TimeLapsed>}
        </CameraActions>
      </FloatPanel>
      <FloatPanel bottom="72px" zIndex={10000} top={0}>
        <MainCanvasPanel />
      </FloatPanel>
      <FloatPanel bottom="200px">
        <QuickMenu />
      </FloatPanel>
      <FloatPanel bottom="100px" left="100px">
        <TransformMode />
      </FloatPanel>

      {/* <FloatPanel transformTop="-50%" top="50%">
        <Plus width={200} />
      </FloatPanel> */}
      <div style={{ flex: 1 }} ref={containerRef}>
        <CenterWrapper>
          <CameraFrame
            containerRef={containerRef}
            updateMaskLocation={setCanvasConfig}
          />
        </CenterWrapper>
        <CanvasWrapper position={canvasConfig}>
          <Canvas
            // shadows
            ref={canvasRef}
            camera={{
              position: sceneConfig?.camera?.position,
            }}
          >
            <JobsProvider>
              <Suspense fallback={null}>
                <SceneSetup />
              </Suspense>
            </JobsProvider>
          </Canvas>
        </CanvasWrapper>
      </div>
    </PageWrapper>
  );
};

const RestrictedCanvas = () => {
  const ref = useRef();

  return (
    <SceneProvider canvasRef={ref}>
      <RecorderProvider canvasRef={ref}>
        <Content canvasRef={ref} />
      </RecorderProvider>
    </SceneProvider>
  );
};
export default RestrictedCanvas;
