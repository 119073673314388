import React from "react";

const Arrow = ({
  color = "#fff",
  width = 16,
  height = 16,
  strokeWidth = 4,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="7.70711"
        y1="17.2929"
        x2="30.7071"
        y2="40.2929"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="29.2929"
        y1="40.2929"
        x2="52.2929"
        y2="17.2929"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
export default Arrow;
