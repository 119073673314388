import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  font-family: inherit;
  color: #fff;
  position: relative;
  border-radius: 12px;

  & label {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0 8px 2px 10px;
    display: flex;
    align-items: center;
    color: #111;
    text-align: center;
    font-size: 12px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    background: #fd0;
  }
  & input {
    width: ${({ dense }) => (dense ? "30px" : "0px")};
    min-width: 0px;
    flex: 1 1 0%;
    height: 100%;
    outline: none;

    background: rgba(255, 255, 255, 0.9);
    font-family: inherit;
    border-radius: inherit;
    border: 0;
    font-size: 12px;
    padding: ${({ dense }) => (dense ? "0" : "4px 0 4px 28px")};
    &:focus {
      outline: 1px solid #fff;
    }
  }
`;
const NumberInput = ({
  value: defaultValue,
  onUpdate,
  label,
  updateKey,
  dense,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = useCallback(
    (v) => {
      const newValue = parseFloat(v.target.value);
      onUpdate(updateKey, newValue);
      setValue(newValue);
    },
    [updateKey, onUpdate]
  );
  return (
    <InputWrapper dense={dense}>
      {label && <label>{label}</label>}
      <input type="number" step="0.1" value={value} onChange={onChange} />
    </InputWrapper>
  );
};
export default NumberInput;
