export const angles = {
  default: {
    label: "Default",
    position: {
      x: 0,
      y: 0,
      z: 5,
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    fov: 35,

    getConfig: ({ position, bbox }) => ({
      position: {
        x: position.x,
        y: position.y + bbox.y * 0.5,
        z: position.z + bbox.z * 10,
      },
      rotation: { x: 0, y: 0, z: 0 },
    }),
  },
  lowAngle: {
    label: "Low Angle",
    position: {
      x: 0,
      y: -2.5,
      z: 2,
    },
    rotation: {
      x: 0.75,
      y: 0,
      z: 0,
    },
    fov: 35,
    getConfig: ({ position, bbox }) => ({
      position: {
        x: position.x,
        y: position.y - bbox.y * 0.5,
        z: position.z + bbox.y,
      },
      rotation: {
        x: 0.75,
        y: 0,
        z: 0,
      },
    }),
  },
  eye: {
    label: "Eye Level",

    position: {
      x: 0,
      y: 1,
      z: 2,
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    fov: 35,
    getConfig: ({ position, bbox }) => ({
      position: {
        x: position.x,
        y: position.y + bbox.y * 0.9,
        z: position.z + bbox.z * 2,
      },
      rotation: { x: 0, y: 0, z: 0 },
    }),
  },
  dutchAngle: {
    label: "Dutch Angle",

    position: { x: 0.08, y: 0.64, z: -0.185 },
    rotation: { x: 0.16, y: -0.13, z: -0.3 },
    fov: 80,
    getConfig: ({ position, bbox }) => ({
      position: {
        x: position.x,
        y: position.y + bbox.y * 0.8,
        z: position.z + bbox.z * 1.6,
      },
      rotation: { x: 0.16, y: -0.13, z: -0.3 },
    }),
  },

  highAngle: {
    label: "High Angle",
    position: { x: 0, y: 2.67, z: 1.2 },
    rotation: { x: -0.75, y: 0, z: 0 },
    fov: 55,
  },
  cowBoy: {
    label: "Cowboy",
    position: { x: 0, y: 0.6, z: 0.95 },
    rotation: { x: 0, y: 0, z: 0 },
    fov: 50,
  },
  kneeLevel: {
    label: "Knee Level",
    position: {
      x: 0,
      y: 1,
      z: 0.5,
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    fov: 10,
  },
  groundLevel: {
    label: "Ground Level",
    position: {
      x: 0,
      y: 1,
      z: 0.5,
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    fov: 10,
  },
  overhead: {
    label: "Overhead Level",
    position: { x: -0.02, y: 3.53, z: -1.0 },
    rotation: { x: -1.58, y: 0, z: -0.92 },
    fov: 65,
  },
};

export const shots = {
  // Wideshot: framing entire character
  wideShot: {
    label: "Wide Shot",
    fov: 80,
  },
  fullShot: {
    label: "Full Shot",
    fov: 35,
  },
  extremeWideShot: {
    label: "Extreme Wide Shot",
    fov: 120,
  },
  closeUp: {
    label: "Close Up",
    fov: 35,
  },
  extremeCloseUp: { label: "Extreme Close Up", fov: 35 },
  mediumCloseUp: { label: "Medium Close Up", fov: 35 },
  mediumShot: { label: "Medium Shot", fov: 35 },
  cowboyShot: { label: "Cowboy Shot", fov: 35 },
  mediumFullShot: { label: "Medium Full Shot", fov: 35 },
};

export const models = {
  humanoid: {
    name: "humanoid",
    src: "/model/humanoid.fbx",
    loadScale: 0.01,
    fileType: "fbx",
    namespace: "Armature|",
    namespaceSort: (name) => name.split("_")[0],
    // position: [-1, 0, 0],
    // scale: [1, 1, 1],
  },
  basicHumanoid: {
    name: "humanoid",
    src: "/model/base_humanoid.glb",
    loadScale: 0.05,
    fileType: "glb",
    bboxOffset: { x: 0, y: -1.5, z: 0 },
    // position: [-1, 0, 0],
    // scale: [1, 1, 1],
  },
  jogging_humanoid: {
    name: "jogging_humanoid",
    src: "/model/jogging_humanoid.fbx",
    loadScale: 0.02,
    fileType: "fbx",
    // position: [-1, 0, 0],
    // scale: [1, 1, 1],
  },
  room: {
    name: "room",
    fileType: "room",
    position: { x: 0, y: 0.75, z: 1 },
    scale: { x: 10, y: 5, z: 10 },
    rotation: { x: 0, y: 0, z: 0 },
    color: [10 / 255, 20 / 255, 30 / 255],
    disableSelect: true,
  },
  joyful_jump: {
    name: "joyful_jump",
    src: "/model/joyful_jump.fbx",
    loadScale: 0.015,
    fileType: "fbx",
  },
};

export const acceptedFileType = [
  "glb",
  "fbx",
  "room",
  "plane",
  "sphere",
  "cube",
  "background",
];
export const scenes = {
  empty: {
    shots: [],
    config: {
      id: "empty",
      background: "#1e3128",
      camera: {
        fps: 24,
        aspect: 1.7777777777777777,
        outputWidth: 1920,
        outputName: "render",
        position: [0, 0, 6],
      },
    },
  },
  ghibli: {
    config: {
      id: "ghibli",
      background: "#1e3128",
      camera: {
        fps: 24,
        aspect: 16 / 9,
        outputWidth: 1920,
        outputName: "render",
      },
    },
    shots: [
      {
        id: 1,
        lights: [
          {
            type: "ambient",
            intensity: 1.5,
          },
          {
            type: "pointLight",
            intensity: 5,
            position: { x: -2, y: 2, z: 0 },
            color: "green",
          },
        ],
        camera: {
          position: {
            x: 0,
            y: 0,
            z: 6,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          fov: 35,
          zoom: 1,
          angle: "eye",
          shot: "wideShot",
        },
        models: [
          {
            ...models.basicHumanoid,
            color: "#e3ad40",
            position: {
              x: 0,
              y: -1.5,
              z: 0,
            },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            rotation: {
              isEuler: true,
              x: 0,
              y: 0,
              z: 0,
            },
          },
          {
            ...models.basicHumanoid,
            color: "#b90504",
            position: {
              x: 0,
              y: 0,
              z: 0,
            },
            scale: {
              x: 0.711074873296798,
              y: 0.7725544715763227,
              z: 1,
            },
            rotation: {
              x: 0,
              y: -0.5243801168747361,
              z: 0,
            },
          },
          {
            name: "background",
            fileType: "plane",
            texture: "/texture/ghibli_field.jpeg",
            scale: { x: 21.5, y: 14, z: 1 },
            position: { x: 0, y: 1.5, z: -9 },
            rotation: { x: -0.7, y: 0, z: 0 },
            disableColor: true,
            preserveAspectRatio: true,
          },
        ],
      },
      // {id: 2,
      //   camera: {
      //     position: [0, 0, 6],
      //     scale: [1, 1, 1],
      //     rotation: [0, 0, 0],
      //     fov: 35,
      //     zoom: 1,
      //     angle: "eye",
      //     shot: "wideShot",
      //   },

      // }
    ],
  },
  room: {
    shots: [
      {
        id: "shot-1",
        name: "Shot 1",
        lights: [
          {
            type: "ambient",
            intensity: 1.5,
          },
          // {
          //   type: "pointLight",
          //   intensity: 5,
          //   position: [-2, 2, 0],
          //   color: "green",
          // },
          {
            type: "spotlight",
            position: { x: 0, y: 2, z: 0 },
            color: "#fff",
            distance: 5,
            intensity: 10,
            angle: Math.PI / 5,
            penumbra: 0.5,
          },
        ],
        camera: {
          position: {
            x: 0,
            y: 0,
            z: 6,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          fov: 35,
          zoom: 1,
        },
        models: [
          {
            id: "475d40e9-3677-45de-a958-1d0019327596",
            name: "humanoid",
            color: "#e3ad40",
            isSelected: false,
            isAnimating: false,
            visible: true,
            src: "/model/base_humanoid.glb",
            loadScale: 0.05,
            fileType: "glb",
            bboxOffset: {
              x: 0,
              y: -1.5,
              z: 0,
            },
            position: {
              x: -1.139600909363067,
              y: 0.014317336603783648,
              z: 0,
            },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
          },
          {
            id: "bf427e1e-d547-4036-adbe-390b26cd61ee",
            name: "humanoid",
            color: "#b90504",
            isSelected: false,
            isAnimating: false,
            visible: true,
            src: "/model/base_humanoid.glb",
            loadScale: 0.05,
            fileType: "glb",
            bboxOffset: {
              x: 0,
              y: -1.5,
              z: 0,
            },
            position: {
              x: 0,
              y: 0,
              z: 0,
            },
            scale: {
              x: 0.711074873296798,
              y: 0.7725544715763227,
              z: 1,
            },
            rotation: {
              x: 0,
              y: -0.5243801168747361,
              z: 0,
            },
          },
          {
            id: "f667eb7a-e831-4db9-a37b-9a41557c12be",
            name: "room",
            color: [
              0.0392156862745098, 0.0784313725490196, 0.11764705882352941,
            ],
            isSelected: false,
            isAnimating: false,
            visible: true,
            fileType: "room",
            position: {
              x: 0,
              y: 0.75,
              z: 1,
            },
            scale: {
              x: 10,
              y: 5,
              z: 10,
            },
            disableSelect: true,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
          },
        ],
      },
      {
        id: "aa873b91-790f-4cc2-8add-d670cdab8565",
        name: "Shot 2",
        models: [
          {
            id: "475d40e9-3677-45de-a958-1d0019327596",
            name: "humanoid",
            color: "#e3ad40",
            isSelected: true,
            isAnimating: false,
            visible: true,
            src: "/model/base_humanoid.glb",
            loadScale: 0.05,
            fileType: "glb",
            bboxOffset: {
              x: 0,
              y: -1.5,
              z: 0,
            },
            position: {
              x: -1.139600909363067,
              y: 0.014317336603783648,
              z: 0,
            },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
          },
          {
            id: "bf427e1e-d547-4036-adbe-390b26cd61ee",
            name: "humanoid",
            color: "#b90504",
            isSelected: false,
            isAnimating: false,
            visible: true,
            src: "/model/base_humanoid.glb",
            loadScale: 0.05,
            fileType: "glb",
            bboxOffset: {
              x: 0,
              y: -1.5,
              z: 0,
            },
            position: {
              x: 0,
              y: 0,
              z: 0,
            },
            scale: {
              x: 0.711074873296798,
              y: 0.7725544715763227,
              z: 1,
            },
            rotation: {
              x: 0,
              y: -0.5243801168747361,
              z: 0,
            },
          },
          {
            id: "f667eb7a-e831-4db9-a37b-9a41557c12be",
            name: "room",
            color: [
              0.0392156862745098, 0.0784313725490196, 0.11764705882352941,
            ],
            isSelected: true,
            isAnimating: false,
            visible: true,
            fileType: "room",
            position: {
              x: 0,
              y: 0.75,
              z: 1,
            },
            scale: {
              x: 12,
              y: 8,
              z: 15,
            },
            disableSelect: true,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
          },
        ],
        camera: {
          position: {
            x: -1.139601,
            y: -2.904555,
            z: 2.837745,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0.75,
            y: 0,
            z: 0,
          },
          fov: 35,
          zoom: 1,
        },
      },
      {
        id: "bf935576-d6f0-4b38-9055-61f64f3b16d5",
        name: "Shot 3",
        models: [
          {
            id: "475d40e9-3677-45de-a958-1d0019327596",
            name: "humanoid",
            color: "#e3ad40",
            isSelected: true,
            isAnimating: false,
            visible: true,
            src: "/model/base_humanoid.glb",
            loadScale: 0.05,
            fileType: "glb",
            bboxOffset: {
              x: 0,
              y: -1.5,
              z: 0,
            },
            position: {
              x: -1.139600909363067,
              y: 0.014317336603783648,
              z: 0,
            },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            rotation: {
              x: 0,
              y: 2.2,
              z: 0,
            },
          },
          {
            id: "bf427e1e-d547-4036-adbe-390b26cd61ee",
            name: "humanoid",
            color: "#b90504",
            isSelected: false,
            isAnimating: false,
            visible: true,
            src: "/model/base_humanoid.glb",
            loadScale: 0.05,
            fileType: "glb",
            bboxOffset: {
              x: 0,
              y: -1.5,
              z: 0,
            },
            position: {
              x: 0,
              y: 0,
              z: 0,
            },
            scale: {
              x: 0.711074873296798,
              y: 0.7725544715763227,
              z: 1,
            },
            rotation: {
              x: 0,
              y: -0.5243801168747361,
              z: 0,
            },
          },
          {
            id: "f667eb7a-e831-4db9-a37b-9a41557c12be",
            name: "room",
            color: [
              0.0392156862745098, 0.0784313725490196, 0.11764705882352941,
            ],
            isSelected: true,
            isAnimating: false,
            visible: true,
            fileType: "room",
            position: {
              x: 0,
              y: 0.75,
              z: 1,
            },
            scale: {
              x: 12,
              y: 8,
              z: 15,
            },
            disableSelect: true,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
          },
        ],
        camera: {
          position: {
            x: -2.039601,
            y: 0.968288,
            z: -1.127727,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0,
            y: -2.3,
            z: 0.4,
          },
          fov: 35,
          zoom: 1,
        },
      },
    ],
    config: {
      id: "ghibli",
      background: "#1e3128",
      camera: {
        fps: 24,
        aspect: 1.7777777777777777,
        outputWidth: 1920,
        outputName: "render",
        position: [0, 0, 6],
      },
    },
  },
  indoor: {
    config: {
      id: "indoor",
      background: "#182c4e",
      camera: {
        position: [0, 0, 6],
        fps: 24,
        aspect: 16 / 9,
        outputWidth: 1920,
        outputName: "render",
      },
    },
    shots: [
      {
        id: 1,
        lights: [
          {
            type: "ambient",
            intensity: 1.5,
          },
          {
            type: "pointLight",
            intensity: 5,
            position: { x: -2, y: 2, z: 0 },
            color: "green",
          },
        ],
        camera: {
          position: {
            x: 0,
            y: 0,
            z: 5,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          fov: 35,
          zoom: 1,
          angle: "eye",
          shot: "wideShot",
        },
        models: [
          {
            ...models.humanoid,
            position: { x: 0, y: -1.5, z: 0 },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            rotation: { x: 0, y: 0, z: 0 },
          },
          models.room,
          ...["red", "green", "yellow", "blue"].map((p, idx) => ({
            name: "Plane",
            fileType: "plane",
            color: p,
            position: { x: -2 + idx * 1.5, y: 1.5, z: -3 },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            rotation: { x: 0, y: 0, z: 0 },
          })),
        ],
      },
    ],
  },
  parasite: {
    shots: [
      {
        id: "7b4d77e4-b6e0-461d-bef5-65f8f76909e2",
        name: "Shot 1",
        models: [
          {
            id: "2a5c57ad-7bca-4c5c-8b23-e0672a8858b9",
            name: "humanoid",
            color: "#9d8f89",
            isSelected: true,
            isAnimating: {
              "Armature|19_walking_up_stairs": false,
              "Armature|01_walk": false,
            },
            visible: true,
            src: "/model/humanoid.fbx",
            loadScale: 0.01,
            fileType: "fbx",
            namespace: "Armature|",
            position: {
              x: 0,
              y: 1.5,
              z: 4.5,
            },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
          },
          {
            id: "c991770f-50a6-4deb-9bc6-adf0488b29e8",
            name: "Cube",
            color: "#e7e9e2",
            isSelected: false,
            isAnimating: false,
            visible: true,
            key: "cube",
            geometry: "cube",
            enableTexture: true,
            scale: {
              x: 3.314200519019142,
              y: 4.390925336421463,
              z: 9.45759532981624,
            },
            position: {
              x: -4.394402988839283,
              y: -2.4480935261551986,
              z: -1.3,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            fileType: "cube",
          },
          {
            id: "d232595a-fe30-4cd8-a599-82c06d621f45",
            name: "room",
            color: "#6d6d6c",
            isSelected: true,
            isAnimating: false,
            visible: true,
            fileType: "room",
            position: {
              x: 3.34335120377399,
              y: 1.12558010296257,
              z: 1,
            },
            scale: {
              x: 16,
              y: 26.3,
              z: 16.9,
            },
            disableSelect: true,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
          },
          {
            id: "1fc0d3ec-4847-4e11-98c2-74d17b153267",
            name: "Cube",
            color: "#a9b3b3",
            isSelected: false,
            isAnimating: false,
            visible: true,
            key: "cube",
            geometry: "cube",
            enableTexture: true,
            scale: {
              x: 1.5,
              y: 0.8,
              z: 0.3,
            },
            position: {
              x: 0.3,
              y: 2.8,
              z: 5.3,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            fileType: "cube",
          },
          {
            id: "3d5e3280-fb56-42cb-a456-24fc777a7dd3",
            name: "Cube",
            color: "#dadada",
            isSelected: false,
            isAnimating: false,
            visible: true,
            key: "cube",
            geometry: "cube",
            enableTexture: true,
            scale: {
              x: 5.3,
              y: -8.5,
              z: 10.1,
            },
            position: {
              x: 9.948182456328,
              y: -2.98857950268297,
              z: -1.6,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            fileType: "cube",
          },
          {
            id: "4bb96ca2-fd1f-4aee-830c-7a76c2c0e8c0",
            name: "Sphere",
            color: "#d6cc5e",
            isSelected: false,
            isAnimating: false,
            visible: true,
            key: "sphere",
            geometry: "sphere",
            enableTexture: true,
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            position: {
              x: 1.8,
              y: 6.1,
              z: -3.2,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            fileType: "sphere",
          },
          {
            id: "95cd8e9e-292b-4b22-b727-67cec6106f70",
            name: "Cube",
            color: "#c0c098",
            isSelected: false,
            isAnimating: false,
            visible: true,
            key: "cube",
            geometry: "cube",
            enableTexture: true,
            scale: {
              x: 0.1,
              y: 5,
              z: 0.1,
            },
            position: {
              x: 1.8,
              y: 8.5,
              z: -3.2,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            fileType: "cube",
          },
          {
            id: "dc672a53-ad1f-44d6-bb60-c78551953def",
            name: "Cube",
            color: "#e1e1e1",
            isSelected: false,
            isAnimating: false,
            visible: true,
            key: "cube",
            geometry: "cube",
            enableTexture: true,
            scale: {
              x: 1,
              y: 10,
              z: 10,
            },
            position: {
              x: -3.8,
              y: 7.775353360883803,
              z: 0,
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            fileType: "cube",
          },
        ],
        camera: {
          position: {
            x: 0,
            y: 3.528311,
            z: 5.641398,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          fov: 80,
          zoom: 1.1,
        },
        lights: [
          {
            type: "spotlight",
            position: {
              x: 0,
              y: 5.2,
              z: 7,
            },
            color: "#fff",
            distance: 5,
            intensity: 3.1,
            angle: 0.6283185307179586,
            penumbra: 0.5,
          },
          {
            type: "pointLight",
            position: {
              x: -0.6,
              y: 2,
              z: 0,
            },
            color: "#aaaaa4",
            intensity: 5,
          },
          {
            type: "area",
            position: {
              x: -4.1,
              y: 2,
              z: 0,
            },
            rotation: {
              x: 0,
              y: 1.4,
              z: 0,
            },
            color: "#e9ff00",
            intensity: 10.1,
            width: 5.7,
            height: 2.5,
          },
        ],
      },
    ],
    config: {
      id: "ghibli",
      background: "#2d3131",
      camera: {
        fps: 24,
        aspect: 2.3333333333333335,
        outputWidth: 1920,
        outputName: "render",
        position: [0, 0, 6],
      },
    },
  },
  building: {
    config: {
      id: "building",
      background: "#1e3128",
      camera: {
        fps: 24,
        aspect: 16 / 9,
        outputWidth: 1920,
        outputName: "render",
      },
    },
    shots: [
      {
        id: "shot-01",
        lights: [
          {
            type: "ambient",
            intensity: 5,
          },
          {
            type: "pointLight",
            intensity: 5,
            position: { x: 0, y: 0, z: 0 },
            color: "yellow",
          },
        ],
        camera: {
          position: {
            x: 0,
            y: 0,
            z: 6,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          fov: 35,
          zoom: 1,
          angle: "dutchAngle",
          shot: "mediumShot",
        },

        models: [
          // { ...models.humanoid, position: [-1, -1, 0], scale: [0.5, 0.5, 0.5] },
          // models.room,
          {
            ...models.humanoid,
            position: { x: 0.25, y: -2, z: -3 },
            scale: { x: 1, y: 1, z: 1 },
            rotation: { x: 0, y: 0, z: 0 },
            color: "#111",
          },
          {
            ...models.joyful_jump,
            position: { x: 0.25, y: -2, z: -3 },
            scale: { x: 1, y: 1, z: 1 },
            rotation: { x: 0, y: 0, z: 0 },
          },
          {
            name: "background",
            fileType: "plane",
            texture:
              "https://images.unsplash.com/photo-1532882479346-cec0b5c151db?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
            scale: { x: 21.5, y: 14, z: 1 },
            position: { x: 0, y: 1.5, z: -9 },
            rotation: { x: -0.7, y: 0, z: 0 },
            disableColor: true,
            preserveAspectRatio: true,
          },
          // {
          //   name: "bushes",
          //   fileType: "plane",
          //   transparent: true,
          //   texture: "/texture/bushes.png",
          //   scale: [4, 2, 1],
          //   position: [0, 0, -2],
          // },
        ],
      },
    ],
  },
};

export const rooms = {
  default: {
    width: 10,
    height: 5,
    depth: 10,
    x: 0,
    y: 1,
    z: -1,
  },
  alley: {
    width: 3,
    height: 10,
    depth: 30,
    x: 0,
    y: 3,
    z: -9,
  },
};

export const aspectRatios = [
  {
    key: "16/9",
    name: "16:9",
    value: 16 / 9,
  },
  {
    key: "4/3",
    name: "4:3",
    value: 4 / 3,
  },
  {
    key: "21/9",
    name: "21:9",
    value: 21 / 9,
  },
  {
    key: "1/1",
    name: "1:1",
    value: 1,
  },
  {
    key: "9/16",
    name: "9:16",
    value: 9 / 16,
  },
];

export const createMeshOptions = [
  {
    key: "cube",
    name: "Cube",
    geometry: "cube",
    enableTexture: true,
    scale: [1, 1, 1],
    position: [0, 0, 0],
    rotation: [0, 0, 0],
  },
  {
    key: "sphere",
    name: "Sphere",
    geometry: "sphere",
    enableTexture: true,
    scale: [1, 1, 1],
    position: [0, 0, 0],
    rotation: [0, 0, 0],
  },
  {
    key: "plane",
    name: "Plane",
    geometry: "plane",
    enableTexture: true,
    scale: [1, 1, 1],
    position: [0, 0, 0],
    rotation: [0, 0, 0],
  },
  { key: "room", name: "Room", geometry: "room", model: models.room },
  {
    key: "humanoid",
    name: "Animated Humanoid",
    geometry: "fbx",
    model: models.humanoid,
  },
  {
    key: "humanoid_2",
    name: "Basic Humanoid",
    geometry: "glb",
    model: models.basicHumanoid,
  },
];
