import React from "react";

const Grab = ({ color = "#fff", width = 16, height = 16, disabled }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="18" r="5" fill={color} />
      <circle cx="29" cy="18" r="5" fill={color} />
      <circle cx="49" cy="18" r="5" fill={color} />
      <circle cx="9" cy="39" r="5" fill={color} />
      <circle cx="29" cy="39" r="5" fill={color} />
      <circle cx="49" cy="39" r="5" fill={color} />
    </svg>
  );
};
export default Grab;
