import * as THREE from "three";
import { Select, useGLTF } from "@react-three/drei";
import React, { useCallback, useEffect, useRef, useState } from "react";

const BasicModel = ({ model, onSelect }) => {
  const ref = useRef();
  const { scene } = useGLTF(model.src);
  const [baseGeometries, setBaseGeometries] = useState([]);

  useEffect(() => {
    if (scene) {
      setBaseGeometries([]);
      scene.traverse((child) => {
        if (child.isMesh) {
          let o = child.clone();
          let newGeo = o.geometry.clone();
          newGeo.scale(model.loadScale, model.loadScale, model.loadScale);
          setBaseGeometries((prev) => [...prev, newGeo]);
        }
      });
    }
  }, [scene, model]);

  const onChange = useCallback(
    (e) => {
      onSelect({
        mesh: !!e.length ? [ref.current] : [],
        model,
      });
    },
    [onSelect, model]
  );

  return (
    <Select onChange={onChange}>
      <group
        ref={ref}
        name={model.id}
        position={model.position}
        rotation={model.rotation}
        scale={model.scale}
      >
        {baseGeometries.map((b) => (
          <mesh key={b.uuid} castShadow>
            <primitive object={b} attach="geometry" />
            <meshMatcapMaterial side={THREE.DoubleSide} color={model.color} />
          </mesh>
        ))}
        {/* <Shadow
          scale={[4, 4, 4]}
          position-y={-1.6}
          // rotation-x={-Math.PI / 2}
          color="green"
        /> */}
      </group>
    </Select>
  );
};

export default BasicModel;
