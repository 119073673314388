import React from "react";
import styled from "styled-components";
import Arrow from "../svgs/Arrow";

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: rotate(${({ expanded }) => (expanded ? "-180deg" : "0")});
  transition: 0.25s ease-in;
`;

const ExpandArrow = ({ expanded }) => (
  <ArrowWrapper expanded={expanded}>
    <Arrow />
  </ArrowWrapper>
);

export default ExpandArrow;
