import * as THREE from "three";
import { Select, useTexture } from "@react-three/drei";
import React, { useMemo, useRef } from "react";
import ImagePlaneShader from "../../shaders/ImagePlaneShader";
import { extend } from "@react-three/fiber";

extend({ ImagePlaneShader });

const ImageTexture = ({ model, uScale }) => {
  const image = useTexture(model.texture);

  return (
    <imagePlaneShader
      uTexture={image}
      transparent={model.transparent}
      uScale={model.preserveAspectRatio ? [uScale?.x, uScale?.y] : model.scale}
      uPixels={[image.image.naturalWidth, image.image.naturalHeight]}
    />
  );
};

const BasicGeometry = ({ model }) => {
  const color = useMemo(() => model.color, [model]);

  const geometry = useMemo(() => {
    switch (model.fileType) {
      case "cube":
        return <boxGeometry />;
      case "sphere":
        return <sphereGeometry />;
      case "plane":
        return <planeGeometry />;
      default:
        return null;
    }
  }, [model]);
  const ref = useRef();

  return (
    <mesh
      name={model.id}
      position={model.position}
      rotation={model.rotation}
      scale={model.scale}
      ref={ref}
      receiveShadow
      castShadow
    >
      {geometry}
      {model.texture ? (
        <ImageTexture model={model} uScale={ref.current?.scale} />
      ) : (
        <meshMatcapMaterial
          wireframe={model.wireframe}
          side={THREE.DoubleSide}
          color={color}
          roughness={0.7}
        />
      )}
    </mesh>
  );
};
const SelectableBasicGeometry = ({ model, onSelect }) => {
  if (model.disableSelect) {
    return <BasicGeometry model={model} />;
  }
  return (
    <Select
      box
      onChange={(e) => {
        onSelect({
          mesh: e,
          model,
        });
      }}
    >
      <BasicGeometry model={model} />
    </Select>
  );
};

export default SelectableBasicGeometry;
