import React from "react";
import styled from "styled-components";
import Play from "../svgs/Play";
import Pause from "../svgs/Pause";

const Button = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  padding: 4px;
  &:hover {
    opacity: 0.5;
  }
`;

const PlayButton = ({ onClick, isPlaying }) => (
  <Button onClick={onClick}>
    {isPlaying ? <Pause color="#FFF" /> : <Play color="#444" />}
  </Button>
);
export default PlayButton;
