// import { Canvas } from "@react-three/fiber";
// import CameraAngle from "./page/CameraAngle";
// import VirtualCamera from "./components/VirtualCamera";
import RestrictedCanvas from "./components/RestrictedCanvas";
// import Test2 from "./page/Test2";
function App() {
  return <RestrictedCanvas />;
  // return <Test2 />;
}

export default App;
