import styled from "styled-components";

export const GeneralButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: ${({ borderWidth = "1px" }) => borderWidth} solid
    ${({ color }) => color || "#999"};
  border-radius: 4px;
  color: ${({ color }) => color || "#999"};
  padding: 2px 6px;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  white-space: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  font-size: ${({ size }) => (size === "sm" ? 12 : 14)}px;
  &:hover {
    opacity: 0.5;
  }
  ${({ selected }) =>
    selected &&
    `
    border-color: #fff;
    color: #fff;
    &:hover {
      opacity: 1;
    }
  
  `}
`;

export const DeleteButton = styled(GeneralButton)`
  color: #fff;
  &:hover {
    opacity: 0.5;
  }
`;

export const SelectButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: 0;
  transition: 0.1s ease-in;
  color: ${({ isActive }) => (isActive ? "#fff" : "#999")};
  flex: 1;
  text-align: left;
  &:hover {
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  }
`;
